import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IndexWord } from '../model/entity/index-word';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constant } from 'app/config/constants';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for index word action
 */
export class IndexWordService {
  /**
   * URL for index word API
   */
  indexWordUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/index-words';
  constructor(private http: HttpClient) {}

  /**
   * Get all index words of selected group
   */
  getIndexWords(): Observable<IndexWord[]> {
    return this.http.get<IndexWord[]>(this.indexWordUrl);
  }

  /**
   * Be used by INDEX-WORD-EDITOR_COMPONENT
   * get all index words of selected group
   *
   * @param indexWordGroupId
   */
  getIndexWordsByGroupId(indexWordGroupId: Number): Observable<IndexWord[]> {
    const params = new HttpParams().set('indexWordGroupId', indexWordGroupId + '');
    return this.http.get<IndexWord[]>(`${this.indexWordUrl}/${Constant.INDEX_WORD_EDITOR_COMPONENT_URL}/get-index-words-by-group-id`, {
      params
    });
  }

  /**
   * Be used by INDEX-WORD-EDITOR_COMPONENT
   * save index word
   *
   * @param indexWord IndexWord need to save
   */
  saveIndexWord(indexWord: IndexWord): Observable<IndexWord> {
    return this.http.post<IndexWord>(`${this.indexWordUrl}/${Constant.INDEX_WORD_EDITOR_COMPONENT_URL}/save`, indexWord);
  }

  /**
   * Be used by INDEX-WORD-EDITOR_COMPONENT
   * delete IndexWord
   *
   * @param indexWord selected index word
   */
  deleteIndexWord(indexWord: IndexWord) {
    const params = new HttpParams().set('indexWordGroupId', indexWord.id + '');
    return this.http.delete(`${this.indexWordUrl}/${Constant.INDEX_WORD_EDITOR_COMPONENT_URL}/delete`, { params });
  }

  /**
   * get index words by name and group id
   * @param payload
   * @returns
   */
  getIndexWordsByNameAndGroupId(payload: any[][]): Observable<IndexWord[]> {
    const params = new HttpParams().set('groupIds', payload[0] + '');
    return this.http.post<IndexWord[]>(
      `${this.indexWordUrl}/${Constant.INDEX_WORD_EDITOR_COMPONENT_URL}/get-index-words-by-name-and-group-id`,
      payload[1],
      {
        params
      }
    );
  }
  /**
   * Be used by INDEX-WORD-EDITOR_COMPONENT
   * get all index words group by group id
   *
   * @param groupIds
   */
  public getIndexWordsByGroupIds(groupIds: Number[]): Observable<IndexWord[]> {
    return this.http.post<IndexWord[]>(
      `${this.indexWordUrl}/${Constant.INDEX_WORD_EDITOR_COMPONENT_URL}/get-index-words-by-group-ids`,
      groupIds
    );
  }

  /**
   * Be used by INDEX-WORD-EDITOR_COMPONENT
   * Is duplicate index word name
   *
   * @param indexWordName
   * @param groupId
   * @param indexWordId
   * @returns true if index word already exists
   */
  public isDuplicateIndexWord(indexWordName: string, groupId: Number, indexWordId: Number): Observable<boolean> {
    const params = new HttpParams()
      .set('indexWordName', indexWordName + '')
      .set('groupId', groupId + '')
      .set('indexWordId', (indexWordId ? indexWordId : 0) + '');
    return this.http.get<boolean>(`${this.indexWordUrl}/${Constant.INDEX_WORD_EDITOR_COMPONENT_URL}/check-duplicate-index-word-name`, {
      params
    });
  }
}
