import { HttpClient, HttpParams } from '@angular/common/http';
import { Template } from '../model/entity/template';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Constant, DisplaysEnum, TemplateModeEnum, TemplateTypeEnum } from 'app/config/constants';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  templateUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/templates';
  templatesDtoUrl = 'api/templatesDto';
  constructor(private http: HttpClient) {}

  /**
   * Be used LCD-LAYOUT-EDITOR-COMPONENT
   * get templates
   *
   * @param templateGroupId
   */
  getTemplatesByGroupId(templateGroupId: Number): Observable<Template[]> {
    const params = new HttpParams().set('templateGroupId', templateGroupId + '');
    return this.http.get<Template[]>(`${this.templateUrl}/${Constant.LCD_LAYOUT_EDITOR_COMPONENT_URL}/get-template-by-group-id`, {
      params
    });
  }

  /**
   * Be used LCD-LAYOUT-EDITOR-COMPONENT
   * add template
   *
   * @param template
   */
  addTemplate(template: Template): Observable<Template> {
    return this.http.post<Template>(`${this.templateUrl}/${Constant.LCD_LAYOUT_EDITOR_COMPONENT_URL}/add`, template);
  }

  /**
   * Be used LCD-LAYOUT-EDITOR-COMPONENT
   * edit template
   *
   * @param template
   */
  updateTemplate(template: Template): Observable<Template> {
    return this.http.put<Template>(`${this.templateUrl}/${Constant.LCD_LAYOUT_EDITOR_COMPONENT_URL}/edit`, template);
  }

  /**
   * Be used LCD-LAYOUT-EDITOR-COMPONENT
   * delete template
   *
   * @param template
   */
  deleteTemplate(template: Template) {
    const params = new HttpParams().set('templateId', template.id + '');
    return this.http.delete(`${this.templateUrl}/${Constant.LCD_LAYOUT_EDITOR_COMPONENT_URL}/delete`, { params });
  }

  /**
   * Be used LCD-LAYOUT-EDITOR-COMPONENT
   * edit templates
   *
   * @param template
   */
  updateDetailedTemplate(template: Template): Observable<Template> {
    return this.http.post<Template>(`${this.templateUrl}/${Constant.LCD_LAYOUT_EDITOR_COMPONENT_URL}/edit-detailed-template`, template);
  }

  /**
   * Be used LCD-LAYOUT-EDITOR-COMPONENT
   * get templates by id
   *
   * @param templateId
   */
  getDetailedTemplateById(templateId: Number): Observable<Template> {
    const params = new HttpParams().set('templateId', `${templateId}`);
    return this.http.get<Template>(`${this.templateUrl}/${Constant.LCD_LAYOUT_EDITOR_COMPONENT_URL}/get-detailed-template-by-id`, {
      params
    });
  }

  /**
   * Be used LCD-LAYOUT-EDITOR-COMPONENT
   * get templates by group mode
   *
   * @param templateGroupMode
   */
  getTemplatesByGroupMode(templateGroupMode: TemplateModeEnum): Observable<Template[]> {
    const params = new HttpParams().set('templateGroupMode', templateGroupMode + '');
    return this.http.get<Template[]>(`${this.templateUrl}/get-detailed-templates-by-group-mode`, { params });
  }

  /**
   * get all template by type
   * @param templateType
   */
  getAllTemplateByType(templateType: TemplateTypeEnum): Observable<Template[]> {
    const params = new HttpParams().set('templateType', templateType + '');
    return this.http.get<Template[]>(`${this.templateUrl}/get-all-template-by-type`, { params });
  }

  /**
   * get same external content
   * @param idsChecked
   * @param isTwoDisplay
   * @param isTimetable
   * @param displayEnum
   */
  getSameExternalContent(idsChecked: Number[], isTwoDisplay: boolean, isTimetable: boolean, displayEnum?: DisplaysEnum) {
    const params = new HttpParams()
      .set('isTwoDisplay', isTwoDisplay + '')
      .set('displayEnum', displayEnum !== undefined ? displayEnum + '' : '-1')
      .set('isTimetable', isTimetable + '');
    return this.http.post<any>(`${this.templateUrl}/get-same-area-external-content`, idsChecked, { params });
  }

  /**
   * export template
   * @param template
   * @returns
   */
  public exportTemplate(template: Template): Observable<any> {
    return this.http.post(`${this.templateUrl}/export-template`, template, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  /**
   * import template
   * @param file
   * @param templateGroupId
   * @returns
   */
  public importTemplate(file: File, templateGroupId: Number): Observable<Template> {
    const formData = new FormData();
    formData.append('file', file);
    const params = new HttpParams().set('templateGroupId', templateGroupId + '');
    return this.http.post<Template>(`${this.templateUrl}/import-template`, formData, { params });
  }

  /**
   * getTemplateByTemplateIds
   * @param templateIds
   * @returns
   */
  public getTemplateByTemplateIds(templateIds: Array<Number>): Observable<any> {
    return this.http.post<Template>(`${this.templateUrl}/get-template-by-ids`, templateIds);
  }

  /**
   * reset and save template
   * @param templateId
   * @returns
   */
  public resetAndSaveTemplate(templateId: Number): Observable<Template> {
    return this.http.post<Template>(`${this.templateUrl}/reset-and-save-template`, templateId);
  }
}
