import { ActionReducerMap } from '@ngrx/store';
import { newsContentTabReducer, weatherContentTabReducer } from 'app/module/external-content-manager/ngrx/content-reducer';
import { miniMediaReducer } from 'app/module/simple-signage-editor/ngrx/content-reducer';
import { templateEditorReducer } from '../module/lcd-layout-editor/ngrx-undo-redo/template-editor.reducer';
import {
  busInfoDisplayEditorReducer,
  dashboardReducer,
  deliveryManagerReducer,
  destinationSignEditorReducer,
  deviceManagerReducer,
  digitalSignageEditorReducer,
  externalContentManagerReducer,
  indexWordEditorReducer,
  lcdLayoutEditorReducer,
  ledLayoutEditorReducer,
  mainReducer,
  masterListEditorReducer,
  mediaManagerReducer,
  projectManagerReducer,
  routeListEditorReducer,
  signageDisplayEditorReducer,
  simpleSignageEditorReducer,
  sortFilterLCDLayoutEditorReducer,
  sortFilterReducer,
  sortFilterUserManagerReducer,
  stationDisplayEditorReducer,
  timetableDisplayEditorReducer,
  timetableOperationManagerReducer,
  userInfoReducer,
  userManagerReducer
} from '../ngrx-component-state-management/component-state.reducer';
import { AppState } from './app.state';

export const appReducer: ActionReducerMap<AppState> = {
  templateEditorState: templateEditorReducer,
  mainState: mainReducer,
  sortFilterState: sortFilterReducer,
  userManagerState: userManagerReducer,
  projectManagerState: projectManagerReducer,
  deviceManagerState: deviceManagerReducer,
  mediaManagerState: mediaManagerReducer,
  indexWordEditorState: indexWordEditorReducer,
  masterListEditorState: masterListEditorReducer,
  routeListEditorState: routeListEditorReducer,
  lcdLayoutEditorState: lcdLayoutEditorReducer,
  ledLayoutEditorState: ledLayoutEditorReducer,
  destinationSignEditorState: destinationSignEditorReducer,
  busInfoDisplayEditorState: busInfoDisplayEditorReducer,
  digitalSignageEditorState: digitalSignageEditorReducer,
  deliveryManagerState: deliveryManagerReducer,
  dashboardState: dashboardReducer,
  stationDisplayEditorState: stationDisplayEditorReducer,
  signageDisplayEditorState: signageDisplayEditorReducer,
  externalContentManagerState: externalContentManagerReducer,
  newsContentTabState: newsContentTabReducer,
  weatherContentTabState: weatherContentTabReducer,
  userInfoState: userInfoReducer,
  timetableEditorState: timetableDisplayEditorReducer,
  timetableOperationManagerState: timetableOperationManagerReducer,
  simpleSignageEditorState: simpleSignageEditorReducer,
  miniMediaState: miniMediaReducer,
  sortFilterUserManagerState: sortFilterUserManagerReducer,
  sortFilterLCDLayoutEditorState: sortFilterLCDLayoutEditorReducer
};
