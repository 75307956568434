import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { MediaLog } from 'app/model/entity/mediaLog';
import { GroupDevice } from 'app/model/entity/simple/group-device';
import { LogPerMedia } from 'app/module/dashboard/dashboard.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Device } from '../model/entity/device';
import { CommonService } from './common.service';
@Injectable({
  providedIn: 'root'
})
/**
 * Service class for device action
 */
export class DeviceService {
  /**
   * URL of device API
   */
  deviceUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/devices';
  /**
   * url customer api
   */
  customerUrl: string;

  constructor(private http: HttpClient, private commonService: CommonService) {
    this.customerUrl = commonService.getCommonObject().customerApiUrl;
  }

  /**
   * Get all device
   * @returns {Observable<Device[]>} list of all device
   */
  getDevice(): Observable<Device[]> {
    return this.http.get<Device[]>(`${this.deviceUrl}`);
  }

  /**
   * Get device by registration id
   * @returns {Observable<Device>}  device
   */
  getDeviceByRegisId(regisId: string): Observable<Device[]> {
    return this.http.get<Device[]>(`${this.deviceUrl}/get-by-registration-id/${regisId}`);
  }

  /**
   * add new device
   * @param device device need to add
   */
  addDevice(device: Device) {
    return this.http.post<Device>(this.deviceUrl, device);
  }

  /**
   * edit device
   * @param Device device need to edit
   */
  editDevice(device: Device): Observable<Device> {
    return this.http.put<Device>(this.deviceUrl, device).pipe(map(() => device));
  }

  /**
   * delete device
   * @param device selected device
   */
  deleteDevice(device: Device) {
    return this.http.delete(`${this.deviceUrl}/${device.id}`);
  }

  /**
   * get log media for devices
   *
   * @param periodFrom
   * @param periodTo
   * @param deviceIds
   * @param playlistNames
   * @param offsetHour
   * @param offsetMinute
   *
   * @returns
   */
  public getLogMediaForDevices(
    periodFrom: string,
    periodTo: string,
    deviceIds: Number[],
    playlistNames: string[],
    offsetHour: number,
    offsetMinute: number
  ): Observable<MediaLog[]> {
    const params = new HttpParams()
      .set('periodFrom', periodFrom)
      .set('periodTo', periodTo)
      .set('deviceIds', deviceIds.join(','))
      .set('playlistNames', playlistNames.join(','))
      .set('offsetHour', `${offsetHour}`)
      .set('offsetMinute', `${offsetMinute}`);
    return this.http.get<MediaLog[]>(`${this.deviceUrl}/get-log-media-for-devices`, { params });
  }

  /**
   * update emergency mode
   */
  updateEmergencyMode(registrationIds: string[], isOnEmergencyMode: boolean): Observable<number[]> {
    const params = new HttpParams().set('registrationIds', registrationIds.join(',')).set('isOnEmergencyMode', isOnEmergencyMode + '');
    return this.http.get<number[]>(`${this.deviceUrl}/update-emergency-mode`, { params });
  }

  /**
   * call api on mode emergency
   */
  onEmergencyMode(payload: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(payload);
    return this.http.post<any>(`${this.customerUrl}/emergency`, body, { headers: headers });
  }

  /**
   * call api off mode emergency
   */
  offEmergencyMode(payload: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(payload);
    return this.http.post<any>(`${this.customerUrl}/emergencyoff`, body, { headers: headers });
  }

  /**
   * @param jobId
   */
  getDevicesByJobId(jobId: string): Observable<Device[]> {
    const params = new HttpParams().set('jobId', jobId);
    return this.http.get<Device[]>(`${this.deviceUrl}/get-devices-by-job-id`, { params });
  }

  /**
   * Be used DELIVERY-MANAGER-COMPONENT
   * update status first time for devices
   *
   * @param devices
   */
  updateStatusFirstTimeForDevices(devices: Device[]): Observable<Device[]> {
    return this.http.post<Device[]>(`${this.deviceUrl}/first-time/update-status-for-devices`, devices);
  }

  /**
   * Be used DELIVERY-MANAGER-COMPONENT
   * update status for devices
   *
   * @param devices
   */
  updateStatusForDevices(devices: Device[]): Observable<Device[]> {
    return this.http.post<Device[]>(`${this.deviceUrl}/update-status-for-devices`, devices);
  }

  /**
   * Be used DASH-BOARD-COMPONENT
   * get device id by registration id
   *
   * @param registrationIds
   * @returns
   */
  public getDataDisplayByRegistrationIDs(registrationIds: Array<string>): Observable<any> {
    return this.http.post<any>(`${this.deviceUrl}/get-device-ids-by-registration-ids`, registrationIds);
  }

  /**
   * Be used DASH-BOARD-COMPONENT
   * get all model names
   *
   * @returns
   */
  public getAllRegistrationIds(): Observable<Array<string>> {
    return this.http.get<Array<string>>(`${this.deviceUrl}/get-registration-ids-all-devices`);
  }

  /**
   * Be used SIMPLE-SIGNAGE-EDITOR-COMPONENT
   * get all devices
   *
   * @returns
   */
  public getAllDevicesForSimple(): Observable<Array<GroupDevice>> {
    return this.http.get<Array<GroupDevice>>(`${this.deviceUrl}/get-all-devices-for-simple`);
  }

  /**
   * Update data for one device
   *
   * @param device
   * @returns
   */
  public updateDataForOneDevice(device: Device): Observable<Device> {
    return this.http.post<Device>(`${this.deviceUrl}/update-mode-auto-operation-for-one-device`, device);
  }

  /**
   * Update time date line for devices
   * @param timeDateLine
   * @param deviceIds
   * @returns
   */
  public updateTimeDateLine(timeDateLine: Number, deviceIds: Array<Number>): Observable<Array<Device>> {
    const params = new HttpParams().set('timeDateLine', timeDateLine + '');
    return this.http.post<Array<Device>>(`${this.deviceUrl}/update-time-date-line-for-device`, deviceIds, { params });
  }

  /**
   * Get device screen capture url
   *
   * @param registrationId
   * @returns
   */
  public getDeviceScreenCaptureUrl(registrationId: string): Observable<string> {
    const params = new HttpParams().set('registrationId', registrationId);
    return this.http.get<string>(`${this.deviceUrl}/get-device-screen-capture-url`, { params });
  }

  /**
   * export media log
   * @param logPlayPerMedias
   * @param headerJson
   * @returns
   */
  public exportMediaLog(logPlayPerMedias: Array<LogPerMedia>, headerJson: string): Observable<any> {
    const params = new HttpParams().set('language', this.commonService.getCommonObject().setting?.language).set('header', headerJson);
    return this.http.post(`${this.deviceUrl}/export-media-log`, logPlayPerMedias, {
      responseType: 'blob',
      observe: 'response',
      params: params
    });
  }

  /**
   * export content report
   * @param listContentReport
   * @param dateHeader
   * @returns
   */
  public exportContentReport(listContentReport: any, dateHeader: string): Observable<any> {
    const params = new HttpParams().set('language', this.commonService.getCommonObject().setting?.language).set('dateHeader', dateHeader);
    return this.http.post(`${this.deviceUrl}/export-content-report`, listContentReport, {
      responseType: 'blob',
      observe: 'response',
      params: params
    });
  }

  /**
   * Get all group and its device for timetable editor
   *
   * @returns {Observable<Device[]>} list of all device
   */
  public getAllDeviceForTimetableEditor(): Observable<GroupDevice[]> {
    return this.http.get<GroupDevice[]>(`${this.deviceUrl}/get-all-device-for-timetable`);
  }
}
