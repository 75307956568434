import { TextHighlightingSetting } from './../model/entity/text-highlighting-setting';
import { TimetableScheduleMerge } from './../model/entity/schedule/timetable-schedule';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { ContentDay } from 'app/model/entity/content-day';
import { DisplaySettingSchedule } from 'app/model/entity/schedule/display-setting-schedule';
import { TimetableDaily } from 'app/model/entity/schedule/timetables-daily';
import { RegexTime } from 'app/module/timetable-editor/timetable-editor.component';
import { Observable, Subject, of, from } from 'rxjs';
import { Timetable as TimetableMerge } from '../model/entity/schedule/timetable';
import { CommonService } from './common.service';
import { UserCalendar } from 'app/model/entity/schedule/user-calendar';

@Injectable({
  providedIn: 'root'
})
export class ScheduleMergeService {
  /**
   * timetable url
   */
  private scheduleMergeUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/schedule-merge';

  constructor(private http: HttpClient, private commonService: CommonService) {}
  /**
   * read excels from client
   * @param files
   * @param regexTime
   * @returns
   */
  readExcelsFromClient(files: File[], regexTime: RegexTime, timeDateLine: string): Observable<any> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('regexTime', JSON.stringify(regexTime));
    formData.append('timeDateLine', timeDateLine);
    return this.http.post<any>(`${this.scheduleMergeUrl}/read-excels`, formData);
  }

  /**
   * get all schedule merge
   * @returns
   */
  public getAllScheduleMerges(): Observable<any> {
    return this.http.get<any>(`${this.scheduleMergeUrl}/get-all-schedule-merge`);
  }

  /**
   * merge schedule
   * @param timetableDailys
   * @returns
   */
  public createDailySchedule(idTimetableSelected: Number): Observable<any> {
    return this.http.post<any>(`${this.scheduleMergeUrl}/create-daily-schedule`, idTimetableSelected);
  }

  /**
   * get data current day
   * @param year
   * @param month
   * @param day
   * @returns
   */
  public getDataCurrentDay(year: string, month: string, day: string, timeZone: any): Observable<any> {
    const params = new HttpParams().set('dateNow', `${year}+${month}+${day}`);
    return this.http.post<any>(`${this.scheduleMergeUrl}/get-data-current-day`, timeZone, { params });
  }

  /**
   * get display setting schedule
   * @returns
   */
  public getDisplaySettingSchedule(): Observable<any> {
    return this.http.get<any>(`${this.scheduleMergeUrl}/get-display-setting-template`);
  }

  /**
   * save display setting schedule
   * @param displaySettingSchedule
   * @returns
   */
  public saveDisplaySettingSchedule(displaySettingSchedule: DisplaySettingSchedule): Observable<any> {
    return this.http.post<any>(`${this.scheduleMergeUrl}/save-display-setting-template`, displaySettingSchedule);
  }

  /**
   * overwrite timetables
   * @param timetables
   * @returns
   */
  public overWriteTimetables(): Observable<any> {
    return this.http.get<any>(`${this.scheduleMergeUrl}/overwrite`);
  }

  /**
   * addTimetables
   * @param timetables
   * @returns
   */
  public addTimetables(): Observable<any> {
    return this.http.get<any>(`${this.scheduleMergeUrl}/add-schedule`);
  }
  /**
   * get calendar by account
   * @param userId
   * @returns
   */
  public getCalendarByAccount(userCalendars: Array<UserCalendar>): Observable<UserCalendar[]> {
    return this.http.post<any>(`${this.scheduleMergeUrl}/get-calendar-by-account`, userCalendars);
  }
  /**
   * write excel
   * @param timetable
   * @returns
   */
  public writeExcel(timetableId: Number, dayInMonth: number, month: number): Observable<any> {
    const params = new HttpParams()
      .set('language', this.commonService.getCommonObject().setting?.language)
      .set('dayInMonth', `${dayInMonth}`)
      .set('month', `${month}`);
    return this.http.post(`${this.scheduleMergeUrl}/write-excel`, timetableId, {
      responseType: 'blob',
      observe: 'response',
      params: params
    });
  }

  /**
   * subject for action updateTime
   */
  private subjectUpdateColor = new Subject<any>();
  /**
   * action updateTime
   */
  actionUpdateColor = this.subjectUpdateColor.asObservable();
  updateColor(moduleName: any) {
    this.subjectUpdateColor.next(moduleName);
  }
  /**
   * Be used by SCHEDULE-MERGE-COMPONENT
   * check exist timetable
   * @param no
   * @param suffix
   * @param id
   * @returns
   */
  public checkExistTimetable(no: string, suffix: string, id: Number): Observable<boolean> {
    const params = new HttpParams()
      .set('no', no)
      .set('suffix', suffix)
      .set('id', `${id}`);
    return this.http.get<boolean>(`${this.scheduleMergeUrl}/check-exist-timetable`, { params });
  }

  /**
   * Be used by SCHEDULE-MERGE-COMPONENT
   * check exist timetable name
   * @param name
   * @param id
   */
  public checkExistTimetableName(name: string, id: Number): Observable<boolean> {
    const params = new HttpParams().set('name', name).set('id', `${id}`);
    return this.http.get<boolean>(`${this.scheduleMergeUrl}/check-exist-timetable-name`, { params });
  }

  /**
   * save timetable
   * @param timetable
   */
  // public saveTimetable(timetable: TimetableMerge): Observable<TimetableMerge> {
  //   return this.http.post<TimetableMerge>(`${this.scheduleMergeUrl}/save-timetable`, timetable);
  // }

  /**
   * save timetable
   * @param timetable
   */
  public saveEditTimetable(id: Number, suffix: string, name: string): Observable<TimetableMerge> {
    const params = new HttpParams()
      .set('id', `${id}`)
      .set('suffix', suffix)
      .set('name', name);
    return this.http.post<TimetableMerge>(`${this.scheduleMergeUrl}/save-edit-timetable`, null, { params });
  }

  /**
   * save timetable
   * @param timetable
   */
  public saveAddDuplicateTimetable(id: Number, no: string, suffix: string, name: string): Observable<TimetableMerge> {
    const params = new HttpParams()
      .set('id', `${id}`)
      .set('no', no)
      .set('suffix', suffix)
      .set('name', name);
    return this.http.post<TimetableMerge>(`${this.scheduleMergeUrl}/save-add-duplicate-timetable`, null, { params });
  }

  /**
   * Be used by SCHEDULE-MERGE-COMPONENT
   * delete timetables
   *s
   * @param timetableIds list timetable's id checked
   */
  deleteTimetables(timetableIds: Array<Number>) {
    return this.http.post(`${this.scheduleMergeUrl}/delete-timetables`, timetableIds);
  }

  /**
   * save highlight setting
   * @param data
   * @returns
   */
  public saveTextHighlight(data: TextHighlightingSetting[]): Observable<TextHighlightingSetting[]> {
    return this.http.post<TextHighlightingSetting[]>(`${this.scheduleMergeUrl}/save-text-highlight`, data);
  }

  /**
   * merge schedule
   * @param timetable
   */
  public mergeSchedule(no: string): Observable<any> {
    return this.http.post<TimetableMerge>(`${this.scheduleMergeUrl}/merge-schedule`, no);
  }

  /**
   *
   * @param index
   * @param schedule
   * @param idTimetable
   * @returns
   */
  public saveScheduleEdit(day: Number, timetableId: Number, isActive: boolean, indexs: Number[]) {
    const params = new HttpParams()
      .set('day', `${day}`)
      .set('idTimetable', `${timetableId}`)
      .set('isActive', `${isActive}`);
    return this.http.post(`${this.scheduleMergeUrl}/save-schedule-edit`, indexs, { params });
  }

  /**
   * get all schedule merge
   * @returns
   */
  public getAllScheduleDaily(): Observable<any> {
    return this.http.get<any>(`${this.scheduleMergeUrl}/get-all-schedule-daily`);
  }

  /**
   * saveTimetableAfterUpdate
   * @param timetableId
   * @returns
   */
  public saveTimetableAfterUpdate(timetableId: Number) {
    return this.http.post(`${this.scheduleMergeUrl}/save-after-update`, timetableId);
  }

  /**
   * saveTimetableAfterUpdate
   * @param timetableId
   * @returns
   */
  public saveHeadersToOperation() {
    return this.http.get(`${this.scheduleMergeUrl}/save-headers-to-operation`);
  }
}
