import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, SortTypeEnum } from 'app/config/constants';
import { Common } from 'app/model/entity/common';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { IHash, OptionFilter, SortFilterObject } from 'app/model/entity/sort-filter-object';
import _ from 'lodash';
import { DialogCustomSortComponent } from 'app/dialog/dialog-custom-sort/dialog-custom-sort.component';
import { SortFilterService } from 'app/service/sort-filter.service';
import { ExecutingService } from 'app/service/executing.service';
import { from, Observable, Subject } from 'rxjs';
import { skip, take, toArray } from 'rxjs/operators';
@Component({
  selector: 'table-sort-filter',
  templateUrl: './table-sort-filter.component.html',
  styleUrls: ['./table-sort-filter.component.scss']
})
export class TableSortFilterComponent implements OnChanges {
  @Input() dataTables: any[];
  @Input() dataTableSelected: any;
  @Input() currentIndexSelected: number;
  @Input() isSortFilter: boolean;
  @Input() headerColumnsOriginal: any[];
  @Input() dataStoredSecond: any;
  @Input() idParent: string = '0';
  @Input() funcName: string = 'table_scroll';
  @Output() onChangeSelectedRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeCheckbox: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeData: EventEmitter<any> = new EventEmitter<any>();

  /**
   * header dataTables
   */
  public headerDataTables: string[] = [];

  /**
   * templateIds;
   */
  public templateIds;
  /**
   * common object
   */
  commonObject: Common;

  /**
   * time date line
   */
  timeDateLine: string;

  /**
   * sort filter variable
   */
  isCheckAllOptionFilter: boolean;
  columnSortFiltering: string;
  isShowPopUpSortFilter: boolean;
  lastColumnFilter: string;
  listFilterDisplay: Array<OptionFilter>;
  listFilterDisplayOrigin: Array<OptionFilter>;
  isFilter: boolean;
  isClear: boolean;
  listCurrentFilter: IHash = {};
  listSorted: any = [];
  reloadDirective = false;
  /**
   * stopLazyScroll
   */
  public stopLazyScroll: Subject<any> = new Subject<any>();

  /**
   * size of data load from api
   */
  private size = 30;
  /**
   * index last of data dataTablesView
   */
  private indexItem = 0;

  /**
   * name edit
   */
  nameEdit: string;
  /**
   * no edit
   */
  noEdit: string;
  /**
   * suffix edit
   */
  suffixEdit: string;

  /**
   * Sort filter object
   */
  private sortFilterObject: SortFilterObject;

  readonly LAST_FILTER = 'lastFilter';
  readonly IS_FILTER = 'isFilter';
  /**
   * dataTable for display
   */
  dataTablesDisplay: any[];

  /**
   * header column show on display
   */
  headerColumns: any = [];

  /**
   * header column original
   */
  // headerColumnsOriginal: any = [
  //   { headerName: this.translateService.instant('schedule-merge.no'), property: 'no', isSortBy: '', isFilterBy: '', },
  //   { headerName: this.translateService.instant('schedule-merge.suffix'), property: 'suffix', isSortBy: '', isFilterBy: '', },
  //   { headerName: this.translateService.instant('schedule-merge.name'), property: 'name', isSortBy: '', isFilterBy: '', style: '' }
  // ];

  constructor(
    private dialogService: DialogService,
    private commonService: CommonService,
    private translateService: TranslateService,
    private sortFilterService: SortFilterService,
    private executingService: ExecutingService
  ) {
    this.commonObject = commonService.getCommonObject();
    this.sortFilterObject = sortFilterService.getSortFilterObject();
    this.sortFilterObject.isSortFilter = false;
    this.getAllSortFilterConditions();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataStoredSecond'] && this.dataStoredSecond && this.dataStoredSecond.sortFilterObject) {
      this.sortFilterObject = this.dataStoredSecond.sortFilterObject;
      this.headerColumns = this.dataStoredSecond.headerColumns;
      this.dataTablesDisplay = this.dataStoredSecond.dataTablesDisplay;
      this.listCurrentFilter = this.dataStoredSecond.listCurrentFilter;
      this.listFilterDisplay = this.dataStoredSecond.listFilterDisplay;
      this.lastColumnFilter = this.dataStoredSecond.lastColumnFilter;
      this.dataTableSelected = this.dataStoredSecond.dataTableSelected;
      if (this.dataTablesView.length == 0 && this.dataStoredSecond.dataTablesDisplay.length > 0) {
        this.loadStaticRow(this.currentIndexSelected);
        this.reloadDirective = true;
      }
    } else {
      if (changes['dataTables']) {
        this.resetSortFilter();
        if (this.dataTables.length > 0) {
          this.multiLanguageTooltip();
          this.selectDataTable(this.dataTablesDisplay[Constant.FIRST_ELEMENT_INDEX], null, null);
          this.loadStaticRow(this.currentIndexSelected);
          this.reloadDirective = true;
        } else {
          this.dataTablesView = [];
        }
      }
      if (changes['isSortFilter']) {
        if (!this.isSortFilter) {
          this.resetSortFilter();
        }
      }
      if (changes['currentIndexSelected']) {
        this.dataTableSelected = this.dataTablesDisplay.find(e => e.indexFilter == this.currentIndexSelected);
        this.loadStaticRow(this.currentIndexSelected);
      }
      if (changes['headerColumnsOriginal']) {
        this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
        this.sortFilterObject.headerColumns = this.headerColumns;
      }
    }
  }
  // checkLoading() {
  //   const parentId = this.idParent;
  //   const element = document.getElementById(parentId + '__');

  //   if (!element) {
  //     setTimeout(() => {
  //       this.checkLoading();
  //     }, 100);
  //   } else {
  //     this.executingService.executed();
  //   }
  // }
  /**
   * convertViewData
   * @param data
   * @param index
   * @returns
   */
  convertViewData(data, index, dataTable) {
    if (!data) {
      return '';
    }
    const dataArrTime = data.split(':');
    if (
      !dataTable.invalid &&
      (index == 1 || index == 0) &&
      ((data.length == 8 && dataArrTime.length == 3) || (data.length == 5 && dataArrTime.length == 2)) &&
      !isNaN(Number(dataArrTime[0]))
    ) {
      let hh = parseInt(dataArrTime[0]) > 23 ? dataArrTime[0] - 24 : dataArrTime[0];
      hh = (hh + '').length == 1 ? '0' + hh : hh;
      return dataArrTime.length == 2 ? hh + ':' + dataArrTime[1] : hh + ':' + dataArrTime[1] + ':' + dataArrTime[2];
    } else {
      return data;
    }
  }

  setDataTable() {
    this.dataTablesDisplay = [...this.dataTables];
    this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);

    // case filter
    if (!_.isEmpty(this.listCurrentFilter)) {
      this.filterDataTableFirstTime();
    }
    // case sort
    if (this.listSorted[Constant.SORT_COLUMN_INDEX]) {
      this.dataTablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    }
    this.convertDataTable();
  }

  /**
   * select dataTable
   * @param dataTable
   * @returns
   */
  public selectDataTable(dataTable: any, event: any, index: number, skipCheckId = false, indexScroll = -1): void {
    if (dataTable?.id == this.dataTableSelected?.id && !skipCheckId) {
      return;
    }

    if (event?.target?.id === 'checkboxSchedule') {
      return;
    }
    this.dataTableSelected = dataTable;
    this.saveSortFilterStateAction();
    this.onChangeSelectedRow.emit({ dataTable, event, index });
  }

  /**
   * change checked
   * @param dataTableId
   * @param event
   */
  public changeCheckedSchedule(index, event, dataRow): void {
    event.stopPropagation();
    this.onChangeCheckbox.emit({ index, event });
  }

  /**
   * Save sort filter state action
   */
  private saveSortFilterStateAction(): void {
    this.onChangeFilter.emit({
      headerColumns: this.headerColumns,
      sortFilterObject: this.sortFilterObject,
      dataTablesDisplay: this.dataTablesDisplay,
      listCurrentFilter: this.listCurrentFilter,
      listFilterDisplay: this.listFilterDisplay,
      lastColumnFilter: this.lastColumnFilter,
      dataTableSelected: this.dataTableSelected
    });
  }

  public loadMoreScrollDown(): void {
    if (this.dataTablesDisplay.length < this.size) {
      return;
    }
    this.getData(this.indexItem, this.size, true);
  }

  public loadMoreScrollUp(): void {
    if (this.dataTablesDisplay.length < this.size) {
      return;
    }
    this.getData(this.indexItem, this.size, false);
  }

  getData(index, size, isScrollDown, loadLast?, scrollTo?) {
    if (index < 0) {
      return;
    } else if (!isScrollDown && index <= size * 3) {
      return;
    }
    this.indexItem = index;
    let dataArr;
    this.getDataFromListDisplay(index, size, isScrollDown).subscribe(data => {
      if (data && data.length > 0) {
        if (isScrollDown) {
          if (loadLast) {
            dataArr = data;
            this.indexItem = index + size;
          } else {
            //if scroll down => push data
            dataArr = [...this.dataTablesView, ...data];
            this.indexItem += size;
          }
        } else {
          //if scroll up => unshift data
          dataArr = [...data, ...this.dataTablesView];
          this.indexItem -= size;
          this.scrollToElementById('0'); //scroll to id (O) before add data.
          setTimeout(() => {
            this.scrollToElementById((this.dataTablesDisplay.length < index ? this.dataTablesDisplay.length + size - index : size) + '');
          }, 300);
        }
        this.dataTablesView = this.arrConvertData(index, size, isScrollDown, dataArr);
        if (scrollTo) {
          setTimeout(() => {
            this.scrollToElementById(parseInt(scrollTo) > this.dataTablesView.length ? this.dataTablesView.length - 1 : scrollTo);
          }, 200);
          // this.scrollToElementById(scrollTo);
        }
      }
    });
  }
  getDataFromListDisplay(index, size, isScrollDown = true): Observable<any> {
    // lazyloading data when scroll
    if (this.dataTablesDisplay) {
      return from(this.dataTablesDisplay).pipe(
        skip(isScrollDown ? index : index - size * 4 > 0 ? index - size * 4 : 0),
        take(this.dataTablesDisplay.length < index ? this.dataTablesDisplay.length + size - index : size),
        toArray()
      );
    } else {
      return from([]);
    }
  }

  arrConvertData(index, size, isScrollDown = true, dataArr): any[] {
    //func to splice arr (lazyloading)
    let numDataNeedSplice = dataArr.length - size * 3;
    if (numDataNeedSplice < 0) {
      numDataNeedSplice = 0;
    }
    if (isScrollDown) {
      dataArr.splice(0, numDataNeedSplice);
    } else {
      dataArr.splice(size * 3, numDataNeedSplice);
    }
    return dataArr;
  }

  loadStaticRow(key) {
    this.indexItem = 0;
    const numSize = (key - (key % this.size)) / this.size;
    let numIndexItem;
    let scrollTo;
    this.dataTablesView = [];
    if (numSize < 2) {
      numIndexItem = 0;
      scrollTo = key - 5;
    } else if ((numSize + 1) * this.size <= this.dataTablesDisplay.length) {
      numIndexItem = this.size * (numSize - 1);
      scrollTo = (key % this.size) - 5 + this.size; //(key % this.size == 0 ? 0 : this.size);
    } else {
      numIndexItem = this.size * (numSize - 2);
      scrollTo = (key % this.size) - 5 + this.size * 2;
    }
    let scrollToE = null;

    if (scrollTo) {
      scrollToE = (scrollTo < 0 ? -1 : scrollTo) + '';
    }
    this.getData(numIndexItem, this.size * 3, true, false, scrollToE);
  }
  /**
   * reset sort filter action
   */
  private resetSortFilter(): void {
    this.listSorted = [];
    this.listCurrentFilter = {};
    this.lastColumnFilter = undefined;
    this.isFilter = undefined;
    this.columnSortFiltering = undefined;
    this.listFilterDisplay = undefined;
    this.dataTablesDisplay = _.cloneDeep(this.dataTables);
    this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
    // store data sort filter
    this.sortFilterObject.isFilter = this.isFilter;
    this.sortFilterObject.listSorted = this.listSorted;
    this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    this.sortFilterObject.columnSortFiltering = this.columnSortFiltering;
    this.sortFilterObject.headerColumns = this.headerColumns;
    if (this.listFilterDisplayOrigin) {
      for (const tmp of this.listFilterDisplayOrigin) {
        tmp.isChecked = true;
      }
    }
    this.setDataTable();
  }
  rowConfigLoad = 50;
  dataTablesView: any[] = [];

  calculatorScoll(indexScroll): any {
    if (!this.dataTableSelected || indexScroll == -1 || this.dataTableSelected.indexFilter < 25) {
      return { id: -1, delay: false };
    }
    this.dataTableSelected?.indexFilter;
    const element = document.getElementById('col-first');
    let numFix = 1;
    if (element.scrollTop > 30) {
      return { id: -10, delay: false };
    } else if (element.scrollTop >= 20) {
      numFix = 0;
    }
    console.log(element.scrollTop);
    const numToScroll = this.dataTableSelected.indexFilter - indexScroll - numFix;
    return { id: numToScroll, delay: true };
  }

  public scrollToElementById(id: string, delay = false) {
    if (id == '-1') {
      var objDiv = document.getElementById('col-first');
      objDiv.scrollTop = 0;
      return;
    } else if (Number(id) == this.dataTablesDisplay.length - 4) {
      var objDiv = document.getElementById('col-first');
      objDiv.scrollTop = objDiv.scrollHeight;
      return;
    }
    if (id == '-10') {
      return;
    }
    const element = document.getElementById(id);
    // if (element && delay) {
    //   element.scrollIntoView({ behavior: 'smooth' });
    //   return;
    // }
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    // if (!element) {
    //   setTimeout(() => {
    //     this.scrollToElementById(id);
    //   }, 100);
    // }
  }
  /**
   * Filter dataTable when open dataTable editor first time
   *
   * @returns
   */
  private filterDataTableFirstTime(): void {
    if (!this.listCurrentFilter) {
      this.selectDataTable(this.dataTables[Constant.FIRST_ELEMENT_INDEX], null, null);
      return;
    }
    let listCurrentFilter = _.cloneDeep(this.listCurrentFilter);
    let columnSortFilters = Object.keys(listCurrentFilter);
    columnSortFilters.forEach(columnSortFilter => {
      this.listFilterDisplay = _.cloneDeep(listCurrentFilter)[columnSortFilter];
      this.listFilterDisplayOrigin = this.listFilterDisplay;
      this.filterDataTable(columnSortFilter, true);
    });
    this.convertDataTable();
  }

  /**
   * filter dataTable
   *
   * @param property
   * @param isFilterFirstTime
   */
  public filterDataTable(property: string, isFilterFirstTime: boolean): void {
    // do not filter all
    if (this.listFilterDisplayOrigin.every(data => !data.isChecked)) {
      this.isShowPopUpSortFilter = false;
      this.saveSortFilterStateAction();
      return;
    }
    this.isFilter = true;
    this.sortFilterObject.isFilter = this.isFilter;
    this.headerColumns.find(data => data.property === property).isFilterBy = property;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.lastColumnFilter = property;
    let columnsFiltered = Object.keys(this.listCurrentFilter);
    // if is not clear last column filter
    if (!this.isClear) {
      this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    }
    // if list option filter checked all or clear last column filter
    if (this.listFilterDisplay.findIndex(data => !data.isChecked) === -1) {
      delete this.listCurrentFilter[property];
      this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
      columnsFiltered = Object.keys(this.listCurrentFilter);
      this.lastColumnFilter = columnsFiltered[columnsFiltered.length - 1];
      this.isClear = false;
      this.isFilter = false;
      this.sortFilterObject.isFilter = this.isFilter;
      this.sortFilterObject.isClear = this.isClear;
      this.headerColumns.find(data => data.property === property).isFilterBy = Constant.EMPTY;
      this.sortFilterObject.headerColumns = this.headerColumns;
      // if filter a column was filtered
    } else {
      if (this.listCurrentFilter[property] && this.lastColumnFilter !== columnsFiltered[columnsFiltered.length - 1]) {
        let nextProperTyFilter = columnsFiltered[columnsFiltered.indexOf(property) + 1];
        this.dataTables?.forEach(element => {
          if (element[this.LAST_FILTER] === property) {
            element[this.LAST_FILTER] = nextProperTyFilter;
          }
        });
        let listDataTableGetOptionFilter = this.dataTables?.filter(
          data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
        );
        let listDataTableOptionFilter = this.getUniqueOption(listDataTableGetOptionFilter, nextProperTyFilter);
        let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
        for (let i = 0; i < listDataTableOptionFilter.length; i++) {
          listOptionFilterNew[i] = {
            isChecked: !listDataTableOptionFilter[i].lastFilter,
            name: listDataTableOptionFilter[i][nextProperTyFilter]
          };
        }
        this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
        delete this.listCurrentFilter[property];
        this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
      }
      // set list option filter property
      this.listCurrentFilter[property] = this.listFilterDisplay;
      this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    }
    this.getCurrentFilter(this.listFilterDisplay, property);
    // get list DataTable show up on screen
    // this.dataTables?.filter(data => data[this.LAST_FILTER]).map(data => (data[Constant.IS_SELECTED] = false));
    let listFilterTmp = _.cloneDeep(this.dataTables);
    for (let filterTmp in this.listCurrentFilter) {
      let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      // filter = filter.map(e=> e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    this.dataTablesDisplay = listFilterTmp;
    this.dataTablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    this.controlCheckBoxCheckAllFilter();
    this.convertDataTable();
  }
  /**
   * get array not duplicate value
   * @param array
   * @param property
   */
  public getUniqueOption = (array, property): any => {
    return _.uniqBy(array, property);
  };

  /**
   * set lastFilter for DataTable to filter or un filter
   * @param currentFilter list option filter property
   * @param property column filtering
   */
  public getCurrentFilter(currentFilter: OptionFilter[], property: string): void {
    for (let i = 0; i < currentFilter.length; i++) {
      if (!currentFilter[i].isChecked) {
        let arr = this.dataTables?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          element[this.IS_FILTER] = true;
          if (!element[this.LAST_FILTER]) {
            element[this.LAST_FILTER] = property;
          }
        });
      } else {
        let arr = this.dataTables?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          if (element[this.LAST_FILTER] == property) {
            element[this.IS_FILTER] = false;
            element[this.LAST_FILTER] = undefined;
          }
        });
      }
    }
  }

  /**
   * control checkBox check all filter when uncheck and checked
   */
  private controlCheckBoxCheckAllFilter(): void {
    this.isCheckAllOptionFilter = this.listFilterDisplayOrigin.every(filter => filter.isChecked);
    this.sortFilterObject.isCheckAllOptionFilter = this.isCheckAllOptionFilter;
  }

  /**
   * reset column sort disable in list
   */
  private resetColumnsSort(): void {
    this.headerColumns.forEach(column => {
      column[Constant.IS_CHOSEN] = false;
      column.isSortBy = Constant.EMPTY;
    });
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
  }

  /**
   * show custom sort dialog and sort
   */
  public showCustomSort(): void {
    this.isShowPopUpSortFilter = false;
    let propertySorts = _.cloneDeep(this.headerColumns).filter(e => e.isHaveSortOrder == true);
    // show dialog custom sort
    this.dialogService.showDialog(DialogCustomSortComponent, { data: { list: [this.listSorted, propertySorts] } }, result => {
      if (result) {
        this.listSorted = result;
        for (let i = 0; i < this.headerColumns.length; i++) {
          let index = this.listSorted[0].findIndex(column => column === this.headerColumns[i]?.property);
          if (index === -1) {
            this.headerColumns[i].isSortBy = Constant.EMPTY;
          } else {
            this.headerColumns[i].isSortBy = this.listSorted[1][index];
          }
        }
        this.sortFilterObject.listSorted = this.listSorted;
        this.sortFilterObject.headerColumns = this.headerColumns;
        // sort
        this.dataTablesDisplay = this.dataTables?.filter(data => !data[this.LAST_FILTER]);
        this.dataTablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
        this.updateColumnCustomSort(this.headerColumns, propertySorts);
        this.convertDataTable();
      }
    });
  }
  /**
   * sort multiple
   * @param dataSort list properties and sort type sorted
   */
  public dynamicSortMultiple(dataSort: any): any {
    return function(object1, object2) {
      let output = 0,
        i = 0;
      while (output == 0 && i < dataSort[0]?.length) {
        let value1 = object1[dataSort[0][i]] ?? Constant.EMPTY; // dataSort[0] is list column sorted
        let value2 = object2[dataSort[0][i]] ?? Constant.EMPTY;
        if (dataSort[1][i] === SortTypeEnum.DESC) {
          // dataSort[1] is list sort type corresponding to column
          output = value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
        } else {
          output = value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }
  /**
   * set up for disable option in custom sort
   *
   * @param columnsBeforeSort
   * @param columnsAfterSort
   */
  private updateColumnCustomSort(columnsBeforeSort: any, columnsAfterSort: any): void {
    columnsAfterSort?.forEach((columnAfter, index) => {
      columnsBeforeSort[index][Constant.IS_CHOSEN] = columnAfter[Constant.IS_CHOSEN];
    });
  }

  /**
   * clear filter
   * @param property name of column clear filter
   */
  public clearFilter(property: string): void {
    this.headerColumns.find(data => data.property == property).isFilterBy = Constant.EMPTY;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.isFilter = false;
    this.sortFilterObject.isFilter = this.isFilter;
    // set all option in list is true
    this.listCurrentFilter[property]?.forEach(element => {
      element.isChecked = true;
    });
    this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    // if is last column filter
    if (property === this.lastColumnFilter) {
      this.isClear = true;
      this.sortFilterObject.isClear = this.isClear;
      this.listFilterDisplayOrigin.forEach(data => (data.isChecked = true));
      this.filterDataTable(property, false);
      // if is not last column filter
    } else {
      let keys = Object.keys(this.listCurrentFilter);
      let nextProperTyFilter = keys[keys.indexOf(property) + 1];
      // set lastFilter is next column filter
      this.dataTables?.forEach(element => {
        if (element[this.LAST_FILTER] === property) {
          element[this.IS_FILTER] = false;
          element[this.LAST_FILTER] = nextProperTyFilter;
        }
      });
      // get new list option filter for next property filter in listCurrentFilter
      let listDataTableGetOptionFilter = this.dataTables?.filter(
        data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
      );
      let listDataTableOptionFilter = this.getUniqueOption(listDataTableGetOptionFilter, nextProperTyFilter);
      let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
      for (let i = 0; i < listDataTableOptionFilter.length; i++) {
        listOptionFilterNew[i] = {
          isChecked: !listDataTableOptionFilter[i].lastFilter,
          name: listDataTableOptionFilter[i][nextProperTyFilter]
        };
      }
      listOptionFilterNew.forEach(element => {
        for (let j = 0; j < this.listCurrentFilter[nextProperTyFilter]?.length; j++) {
          if (element.name === this.listCurrentFilter[nextProperTyFilter][j].name) {
            element.isChecked = this.listCurrentFilter[nextProperTyFilter][j].isChecked;
          }
        }
      });
      // set new list option filter for next property filter in listCurrentFilter
      this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
      this.isShowPopUpSortFilter = false;
      delete this.listCurrentFilter[property];
      this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
      this.controlCheckBoxCheckAllFilter();
      this.convertDataTable();
    }
  }

  /**
   * check select all option
   */
  public checkAllOptionFilter(): void {
    this.isCheckAllOptionFilter = !this.isCheckAllOptionFilter;
    this.sortFilterObject.isCheckAllOptionFilter = this.isCheckAllOptionFilter;
    this.listFilterDisplayOrigin.forEach(option => {
      option.isChecked = this.isCheckAllOptionFilter;
    });
    this.listFilterDisplay = [...this.listFilterDisplayOrigin];
  }

  /**
   * change checked
   * @param index index of option filter
   */
  public checkOptionFilter(index: number): void {
    this.listFilterDisplayOrigin[index].isChecked = !this.listFilterDisplayOrigin[index].isChecked;
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * show pop up sort filter
   * @param column name column
   * @param event
   */
  public showPopupSortFilter(column: string, event): void {
    if (this.dataTableSelected?.isEdit) {
      return;
    }
    event.stopPropagation();
    this.isShowPopUpSortFilter = !this.isShowPopUpSortFilter;
    // if is show
    if (this.isShowPopUpSortFilter) {
      this.columnSortFiltering = column;
      this.sortFilterObject.columnSortFiltering = this.columnSortFiltering;
      this.fetchFilterData(column);
    }
  }

  /**
   * fetch data filter to pop up
   * @param property column show popup
   */
  public fetchFilterData(property: string): void {
    // let listDataTableGetOptionFilter = this.dataTablesDisplay.filter(
    //   data => !data[this.LAST_FILTER] || data[this.LAST_FILTER] === property
    // );
    let isFiltered = false;
    let listFilterTmp = _.cloneDeep(this.dataTables);
    let listFilterInProperty = [];
    for (let filterTmp in this.listCurrentFilter) {
      if (filterTmp == property) {
        isFiltered = true;
        listFilterInProperty = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
        continue;
      }

      let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      // filter = filter.map(e=> e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    let listDataTableGetOptionFilter = _.cloneDeep(listFilterTmp);
    let listDataTableOptionFilter: any[] = this.getUniqueOption(listDataTableGetOptionFilter, property);
    // if not last column filter
    if (this.lastColumnFilter !== property) {
      this.listFilterDisplay = [];
      for (let i = 0; i < listDataTableOptionFilter.length; i++) {
        //get list option filter
        this.listFilterDisplay[i] = {
          isChecked: listFilterInProperty.length == 0 ? true : listFilterInProperty.includes(listDataTableOptionFilter[i][property]),
          // isChecked: !listDataTableOptionFilter[i][this.IS_FILTER],
          name: listDataTableOptionFilter[i][property]
        };
      }
      // if is last column filter
    } else {
      this.listFilterDisplay = this.listCurrentFilter[property];
      // update if add
      listDataTableOptionFilter.forEach(dataTable => {
        if (!this.listFilterDisplay.find(optionFilter => optionFilter.name === dataTable[property])) {
          this.listFilterDisplay.push({
            isChecked: !isFiltered,
            name: dataTable[property]
          });
        }
      });
      // remove old value
      this.listFilterDisplay?.forEach(option => {
        if (option.isChecked && !listDataTableOptionFilter.find(dataTable => dataTable[property] === option.name)) {
          this.listFilterDisplay = this.listFilterDisplay.filter(data => data.name !== option.name);
        }
      });
    }
    this.listFilterDisplay = _.sortBy(this.listFilterDisplay, ['name']);
    // get list memorize checked
    this.listFilterDisplayOrigin = _.cloneDeep(this.listFilterDisplay);
    this.controlCheckBoxCheckAllFilter();
  }
  /**
   * Get all sort filter conditions
   */
  private getAllSortFilterConditions(): void {
    // get data from store
    this.isFilter = this.sortFilterObject?.isFilter;
    this.isSortFilter = this.sortFilterObject?.isSortFilter;
    this.isCheckAllOptionFilter = this.sortFilterObject?.isCheckAllOptionFilter;
    this.columnSortFiltering = this.sortFilterObject?.columnSortFiltering;
    this.listCurrentFilter = this.sortFilterObject?.listCurrentFilter;
    this.isClear = this.sortFilterObject?.isClear;
    this.listSorted = this.sortFilterObject?.listSorted;
    if (this.isSortFilter && (!_.isEmpty(this.listCurrentFilter) || this.listSorted[Constant.SORT_COLUMN_INDEX])) {
      this.headerColumns = this.sortFilterObject?.headerColumns;
    } else {
      this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
    }
  }
  /**
   * sort basic
   * @param property property sorted
   * @param type type sort
   */
  public sortProperty(property: string, type: string): void {
    this.listSorted = [[property], [type]];
    this.dataTablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    // store data sort filter
    this.sortFilterObject.listSorted = this.listSorted;
    // remove all sort of all column
    this.resetColumnsSort();
    // set columns is sorting
    let indexColumnSort = this.headerColumns.findIndex(data => data.property === property);
    this.headerColumns[indexColumnSort].isSortBy = type;
    this.headerColumns[indexColumnSort][Constant.IS_CHOSEN] = true;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.convertDataTable();
  }
  public multiLanguageTooltip(): void {
    if (!this.dataTablesDisplay?.length) {
      return;
    }
    this.dataTablesDisplay.forEach(item => {
      let timeInvalid = Helper.formatString(
        this.translateService.instant('schedule-merge.time-invalid'),
        `${
          this.headerColumnsOriginal[0].type == 'checkbox'
            ? this.headerColumnsOriginal[1].headerName
            : this.headerColumnsOriginal[0].headerName
        }`
      );
      if (item.invalid) {
        item.titleTooltip = timeInvalid;
      }
    });
  }
  convertDataTable() {
    for (let [index, dataRow] of this.dataTablesDisplay.entries()) {
      dataRow.indexFilter = index;
    }
    this.onChangeData.emit(this.dataTablesDisplay);
    this.selectDataTable(this.dataTablesDisplay[Constant.FIRST_ELEMENT_INDEX], null, null, true);
    this.loadStaticRow(this.currentIndexSelected);
  }
}

export enum Tab_Enum {
  CONFIRMATION,
  MONTHLY,
  DAILY
}
