import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { ConditionMedia } from 'app/model/entity/condition-media';
import { SimpleMedia } from 'app/model/entity/simple/simple-media';
import { SimpleMediaValidator } from 'app/model/entity/simple/simple-media-validator';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for simple media action
 */
export class SimpleMediaService {
  /**
   * URL for common simple media
   */
  simpleMediaUrl = Constant.BACKEND_URL + Constant.DSC_SERVICE_URL + 'api/simple-medias';

  constructor(private http: HttpClient) {}

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * get medias by folder id
   *
   * @param folderId
   * @returns
   */
  getMediasByFolderId(folderId: Number): Observable<Array<SimpleMedia>> {
    const params = new HttpParams().set('folderId', `${folderId}`);
    return this.http.get<Array<SimpleMedia>>(`${this.simpleMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/get-medias-by-folder-id`, {
      params
    });
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * Add new simple media
   *
   * @param file
   * @param folderId
   * @returns
   */
  addNewSimpleMedia(file: File, folderId: Number): Observable<SimpleMedia> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folderId', `${folderId}`);
    formData.append('size', `${file.size}`);
    return this.http.post<SimpleMedia>(`${this.simpleMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/add-new-simple-media`, formData);
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * save simple media dropped
   *
   * @param simpleMedia
   * @param folderId
   * @returns
   */
  saveMediaDroppedIn(simpleMedia: SimpleMedia, folderId: Number): Observable<SimpleMedia> {
    const params = new HttpParams().set('folderId', `${folderId}`);
    return this.http.post<SimpleMedia>(`${this.simpleMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/save-media-dropped`, simpleMedia, {
      params
    });
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * delete media
   *
   * @param id
   * @returns
   */
  deleteMedia(id: Number) {
    const params = new HttpParams().set('mediaId', `${id}`);
    return this.http.delete(`${this.simpleMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/delete`, {
      params
    });
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * save simple media dropped in folder
   *
   * @param simpleMedia
   * @param folderId
   * @returns
   */
  saveMediaDroppedInFolder(simpleMedia: SimpleMedia, folderId: Number): Observable<SimpleMedia> {
    const params = new HttpParams().set('folderId', `${folderId}`);
    return this.http.post<SimpleMedia>(
      `${this.simpleMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/save-media-dropped-in-folder`,
      simpleMedia,
      {
        params
      }
    );
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * search by name
   *
   * @param name
   * @returns
   */
  searchByName(name: string): Observable<Array<SimpleMedia>> {
    const params = new HttpParams().set('name', `${name}`);
    return this.http.get<Array<SimpleMedia>>(`${this.simpleMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/search-by-name`, {
      params
    });
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * check exist media used
   *
   * @param idMedia
   * @returns
   */
  checkExistMediaUsed(idMedia: Number): Observable<boolean> {
    const params = new HttpParams().set('idMedia', `${idMedia}`);
    return this.http.get<boolean>(`${this.simpleMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/check-exist-media-used`, {
      params
    });
  }
  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * Get simple media validator
   *
   * @returns
   */
  public getSimpleMediaValidator(): Observable<SimpleMediaValidator> {
    return this.http.get<SimpleMediaValidator>(`${this.simpleMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/get-simple-media-validator`);
  }
  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * Get media by name and type
   *
   * @param mediaName
   * @param mediaType
   */
  public getMediaByNameAndType(mediaName: string, mediaType: string): Observable<Array<SimpleMedia>> {
    const params = new HttpParams().set('mediaName', `${mediaName}`).set('mediaType', `${mediaType}`);
    return this.http.get<Array<SimpleMedia>>(`${this.simpleMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/get-media-by-name-and-type`, {
      params
    });
  }

  /**
   * Get media name in file log
   *
   * @param medias
   * @returns
   */
  public getSimpleMediaNameInLog(medias: Array<string>): Observable<Array<SimpleMedia>> {
    return this.http.post<Array<SimpleMedia>>(`${this.simpleMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/get-media-in-file-log`, medias);
  }

  /**
   * Get media by extract condition
   *
   * @param conditionMediaLog
   * @returns
   */
  public getMediaByExtractCondition(conditionMediaLog: ConditionMedia): Observable<Array<string>> {
    return this.http.post<Array<string>>(
      `${this.simpleMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/get-media-by-extract-condition`,
      conditionMediaLog
    );
  }

  /**
   * Get media by id
   *
   * @param id
   * @returns
   */
  public getMediaById(id: Number): Observable<SimpleMedia> {
    const params = new HttpParams().set('id', `${id}`);
    return this.http.get<SimpleMedia>(`${this.simpleMediaUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/get-media-by-id`, { params });
  }
}
