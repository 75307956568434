import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomTag } from '../model/entity/custom-tag';
import { Constant } from 'app/config/constants';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for custom tag action
 */
export class CustomTagService {
  /**
   * URL of custom tag API
   */
  customTagUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/custom-tags';

  constructor(private http: HttpClient) {}

  /**
   * get all custom tag
   * @returns {Observable<CustomTag[]>} list of all custom tag
   */
  getCustomTag(): Observable<CustomTag[]> {
    return this.http.get<CustomTag[]>(this.customTagUrl);
  }

  /**
   * add new custom tag
   * @param customTag new custom tag need to add
   */
  addCustomTag(customTag: CustomTag) {
    return this.http.post(this.customTagUrl, customTag);
  }

  /**
   * edit custom tag
   * @param customTag custom tag edit
   */
  editCustomTag(customTag: CustomTag): Observable<CustomTag> {
    return this.http.put<CustomTag>(this.customTagUrl, customTag);
  }

  /**
   * delete selected custom tag
   * @param {CustomTag} customTag selected custom tag
   */
  deleteCustomTag(customTag: CustomTag) {
    return this.http.delete(`${this.customTagUrl}/${customTag.id}`);
  }
}
