import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class APICustomerService {
  /**
   * url customer api
   */
  customerUrl: string;

  constructor(private http: HttpClient, private commonService: CommonService) {
    this.customerUrl = commonService.getCommonObject().customerApiUrl;
  }

  /**
   * get latest media log
   * @param registrationIds
   * @returns
   */
  public getLatestMediaLog(registrationIds: string[]): Observable<any> {
    const payload = { id: registrationIds };
    return this.http.post<any>(`${this.customerUrl}/medialog`, payload);
  }

  /**
   * get current status request
   * @param registrationIds
   * @returns
   */
  public getCurrentStatusRequest(registrationIds: string[]): Observable<any> {
    const payload = { id: registrationIds, timeoutSec: 2 };
    return this.http.post<any>(`${this.customerUrl}/status`, payload);
  }

  /**
   * change timetable mode
   *
   * @param payload
   * @returns
   */
  public changeTimetableMode(payload: any): Observable<any> {
    return this.http.post(`${this.customerUrl}/timetable_mode`, payload);
  }

  /**
   * change timetable delay info
   *
   * @param payload
   * @returns
   */
  public changeTimetableDelayInfo(payload: any): Observable<any> {
    return this.http.post(`${this.customerUrl}/timetable_delay_information`, payload);
  }

  /**
   * change timetable display index
   *
   * @param payload
   * @returns
   */
  public changeTimetableDisplayIndex(payload: any): Observable<any> {
    return this.http.post(`${this.customerUrl}/timetable_display_index`, payload);
  }

  /**
   * delivery dynamic message
   *
   * @param payload
   * @returns
   */
  public deliveryDynamicMessage(payload: any): Observable<any> {
    return this.http.post(`${this.customerUrl}/dynamic_message`, payload);
  }

  /**
   * get data for monitor mode preview
   *
   * @param payload registration id
   * @returns
   */
  public getTimetableRealtimeStatus(payload: any): Observable<any> {
    return this.http.post(`${this.customerUrl}/timetable_realtime_status`, payload);
  }

  /**
   * set shutdown time
   *
   * @param payload
   * @returns
   */
  public setShutdownTime(payload: any): Observable<any> {
    return this.http.post(`${this.customerUrl}/set_shutdown_time`, payload);
  }

  /**
   * set display
   *
   * @param payload
   * @returns
   */
  public setDisplay(payload: any): Observable<any> {
    return this.http.post(`${this.customerUrl}/set_display`, payload);
  }

  /**
   * get realtime status request
   * @param payload
   * @returns
   */
  public getRealtimeStatusRequest(payload: any): Observable<any> {
    return this.http.post<any>(`${this.customerUrl}/status`, payload);
  }

  /**
   * Capture screen device
   *
   * @param payload
   * @returns
   */
  public captureScreenDevice(payload: any): Observable<any> {
    return this.http.post<any>(`${this.customerUrl}/screen_capture`, payload);
  }

  /**
   * group create
   *
   * @param payload
   * @returns
   */
  public groupCreate(payload: any): Observable<any> {
    return this.http.post(`${this.customerUrl}/group_create`, payload);
  }

  /**
   * group device add
   *
   * @param payload
   * @returns
   */
  public groupDeviceAdd(payload: any): Observable<any> {
    return this.http.post(`${this.customerUrl}/group_device_add`, payload);
  }

  /**
   * group device remove
   *
   * @param payload
   * @returns
   */
  public groupDeviceRemove(payload: any): Observable<any> {
    return this.http.post(`${this.customerUrl}/group_device_remove`, payload);
  }

  /**
   * group delete
   *
   * @param payload
   * @returns
   */
  public groupDelete(payload: any): Observable<any> {
    return this.http.post(`${this.customerUrl}/group_delete`, payload);
  }

  /**
   * call API job process details
   *
   * @param payload
   * @returns
   */
  public jobProcessDetails(payload: any): Observable<any> {
    return this.http.post<any>(`${this.customerUrl}/job_process_details`, payload);
  }

  /**
   * call API device list for job
   *
   * @param payload
   * @returns
   */
  public deviceListForJob(payload: any): Observable<any> {
    return this.http.post<any>(`${this.customerUrl}/device_list_for_job`, payload);
  }

  /**
   * call API group delivery
   *
   * @param payload
   * @returns
   */
  public groupDelivery(payload: any): Observable<any> {
    return this.http.post<any>(`${this.customerUrl}/group_delivery`, payload);
  }

  /**
   * call API single delivery
   *
   * @param payload
   * @returns
   */
  public singleDelivery(payload: any): Observable<any> {
    return this.http.post<any>(`${this.customerUrl}/single_delivery`, payload);
  }

  /**
   * update Data Schedule Operation
   * @param api
   * @param payload
   * @returns
   */
  public updateDataScheduleOperation(api: string, payload: any): Observable<any> {
    return this.http.post<any>(`${api}`, payload);
  }
  /**
   * refreshBrowserDevice
   * @param payload
   * @returns
   */
  public refreshBrowserDevice(payload: any): Observable<any> {
    return this.http.post<any>(`${this.customerUrl}/refresh`, payload);
  }
}
