import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { Timetable } from 'app/model/entity/timetable';
import { RegexTime } from 'app/module/timetable-editor/timetable-editor.component';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Class TimetableService
 */
export class TimetableService {
  /**
   * timetable url
   */
  private timetableUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/timetables';

  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * Be used by TIMETABLE-COMPONENT
   *
   * get timetables
   */
  getAllTimetables(): Observable<Array<Timetable>> {
    return this.http.get<Array<Timetable>>(`${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/get-all-timetables`);
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * save label for timetables
   *
   * @param timetables
   */
  saveLabelForTimetables(timetables: Array<Timetable>): Observable<Array<Timetable>> {
    return this.http.post<Array<Timetable>>(
      `${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/save-label-for-timetables`,
      timetables
    );
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * save timetable
   *
   * @param timetable
   */
  saveTimetable(timetable: Timetable): Observable<Timetable> {
    return this.http.post<Timetable>(`${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/save`, timetable);
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * write excel
   *
   * @param timetable
   * @returns
   */
  writeExcel(timetable: Timetable): Observable<any> {
    const params = new HttpParams().set('language', this.commonService.getCommonObject().setting?.language);
    return this.http.post(`${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/write-excel`, timetable, {
      responseType: 'blob',
      observe: 'response',
      params: params
    });
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * read excels from client
   *
   * @param files
   * @param regexTime
   * @returns
   */
  readExcelsFromClient(files: File[], regexTime: RegexTime): Observable<any> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('regexTime', JSON.stringify(regexTime));
    return this.http.post<any>(`${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/read-excels`, formData);
  }

  /**
   * change templates
   *
   * @param timetablesSelected
   * @returns
   */
  changeTemplates(timetablesSelected: any[]): Observable<any> {
    return this.http.post<any>(`${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/change-template`, timetablesSelected);
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * delete timetables
   *
   * @param timetableIds list timetable's id checked
   */
  deleteTimetables(timetableIds: Array<Number>) {
    return this.http.post(`${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/delete-timetables`, timetableIds);
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * duplicate timetable
   *
   * @param timetable
   * @param oldTimetableId
   * @returns
   */
  duplicateTimetable(timetable: Timetable, oldTimetableId: Number): Observable<Timetable> {
    const params = new HttpParams().set('oldTimetableId', `${oldTimetableId}`);
    return this.http.post<Timetable>(`${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/duplicate-timetable`, timetable, { params });
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * check exist timetable
   *
   * @param id
   * @param no
   * @param suffix
   */
  checkExistTimetable(no: string, suffix: string, id: Number): Observable<boolean> {
    const params = new HttpParams()
      .set('no', no)
      .set('suffix', suffix)
      .set('id', `${id}`);
    return this.http.get<boolean>(`${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/check-exist-timetable`, { params });
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * get timetable with full data template by id
   *
   * @param timetableId timetable id
   */
  getTimetableWithFullDataTemplateById(timetableId: Number): Observable<Timetable> {
    const params = new HttpParams().set('timetableId', timetableId + '');
    return this.http.get<Timetable>(`${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/get-timetable-with-full-data-template`, {
      params
    });
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   *
   * get timetables with schedule
   */
  getTimetablesWithSchedule(): Observable<Array<Timetable>> {
    return this.http.get<Array<Timetable>>(`${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/get-timetables-with-schedule`);
  }

  /**
   * get timetable by device id and current date
   *
   * @param deviceId
   * @param currentDate
   * @returns
   */
  getTimetableByDeviceIdAndCurrentDate(deviceId: number, currentDate: any) {
    const params = new HttpParams().set('deviceId', deviceId + '').set('currentDate', currentDate);
    return this.http.get<Timetable>(`${this.timetableUrl}/get-timetable-device-id-and-current-date`, { params });
  }

  /**
   * Be used by TIMETABLE-COMPONENT
   * check exist timetable's name
   *
   * @param name
   * @param id
   */
  checkExistTimetableName(name: string, id: Number): Observable<boolean> {
    const params = new HttpParams().set('name', name).set('id', `${id}`);
    return this.http.get<boolean>(`${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/check-exist-timetable-name`, { params });
  }

  /**
   * check exist template main
   * @param deviceIds
   * @returns
   */
  checkExistTemplateMain(deviceIds: Array<Number>): Observable<boolean> {
    return this.http.post<boolean>(`${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/check-exist-template-main`, deviceIds);
  }

  /**
   * Be used by TIMETABLE-OPERATION-COMPONENT
   * get timetable with full data by style id
   *
   * @param styleId
   */
  getTimetableWithFullDataByStyleId(styleId: string): Observable<Timetable> {
    const params = new HttpParams().set('styleId', styleId);
    return this.http.get<Timetable>(`${this.timetableUrl}/${Constant.TIMETABLE_OPERATION_URL}/get-timetable-with-full-data`, {
      params
    });
  }

  /**
   * save timetables when import list timetable
   *
   * @param timetables
   * @returns
   */
  saveTimetables(timetables: Array<Timetable>): Observable<Array<Timetable>> {
    return this.http.post<any>(`${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/save-timetables`, timetables);
  }

  /**
   * save timetables duplicated when import list timetable
   *
   * @param timetables
   * @returns
   */
  saveTimetablesDuplicated(timetables: Array<Timetable>): Observable<Array<Timetable>> {
    return this.http.post<any>(`${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/save-timetables-duplicate`, timetables);
  }

  /**
   * check timetables setting calendar
   * @param timetableIds
   * @returns
   */
  public checkTimetablesSettingCalendar(timetableIds: Array<Number>): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.timetableUrl}/${Constant.TIMETABLE_COMPONENT_URL}/check-timetables-setting-calendar`,
      timetableIds
    );
  }
}
