import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { WeatherContentDetail } from 'app/model/entity/weather-content-detail';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WeatherContentDetailService {
  /**
   * URL for weather-content-detail API
   */
  private readonly weatherContentDetailUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/weather-content-details';

  constructor(private http: HttpClient) {}

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * get weather content details by content id
   *
   * @param contentId
   */
  getWeatherContentDetailsByContentId(contentId: Number): Observable<WeatherContentDetail[]> {
    const params = new HttpParams().set('contentId', contentId + '');
    return this.http.get<WeatherContentDetail[]>(`${this.weatherContentDetailUrl}/get-weather-content-details-by-content-id`, { params });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * save weather content details
   *
   * @param contentId
   */
  saveWeatherContentDetail(weatherContentDetails: WeatherContentDetail[], contentId: Number) {
    const params = new HttpParams().set('contentId', contentId + '');
    return this.http.post<WeatherContentDetail[]>(`${this.weatherContentDetailUrl}/save-weather-content-details`, weatherContentDetails, {
      params
    });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * reload data
   *
   * @param weatherContentDetails
   */
  reloadWeatherContentDetails(weatherContentDetails: WeatherContentDetail[]) {
    return this.http.post<WeatherContentDetail[]>(`${this.weatherContentDetailUrl}/reload-weather-content-details`, weatherContentDetails);
  }
}
