import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constant } from '../config/constants';
import { IUser } from '../core/user/user.model';
import { Privilege } from '../model/entity/privilege';

@Injectable({
  providedIn: 'root'
})
/**
 * Class UserService
 */
export class UserService {
  /**
   * user url
   */
  private userUrl = Constant.BACKEND_URL + Constant.UAA_SERVICE_URL + 'api/users';
  private registerUrl = Constant.BACKEND_URL + Constant.UAA_SERVICE_URL + 'api/register';
  /**
   * privilege url
   */
  private privilegeUrl = Constant.BACKEND_URL + Constant.UAA_SERVICE_URL + 'api/users/privileges';

  constructor(private http: HttpClient) {}

  /**
   * Get list of users
   */
  getUsers(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${this.userUrl}/getall`);
  }

  /**
   * add a new user
   * @param {User} user new user
   */
  addUser(user: IUser): Observable<IUser> {
    return this.http.post<IUser>(this.registerUrl, user);
  }

  /**
   * update selected user
   * @param {User} user selected user
   */
  updateUser(user: IUser): Observable<IUser> {
    return this.http.put<IUser>(this.userUrl, user);
  }

  /**
   * delete selected user
   * @param {User} user selected user
   */
  deleteUser(user: IUser) {
    return this.http.delete(`${this.userUrl}/${user.userId}`);
  }

  /**
   * Get user by id
   * @param userId user ID
   */
  getUserById(userId: string): Observable<IUser> {
    return this.http.get<IUser>(`${this.userUrl}/${userId}`);
  }

  /**
   * get privileges by user role
   *
   * @param roleId  roleId
   * @param isUserRoot
   * @returns
   */
  getPrivilegesByUserRole(roleId: Number, isUserRoot: boolean): Observable<Privilege[]> {
    const params = new HttpParams().set('roleId', roleId + '').set(Constant.IS_USER_ROOT, isUserRoot + '');
    return this.http.get<Privilege[]>(`${this.privilegeUrl}/get-privileges-by-user-role`, { params });
  }

  /**
   * update privilege
   * @param {Privilege} privilege privilege
   */
  updatePrivilege(privileges: Privilege[]): Observable<Privilege[]> {
    return this.http.post<Privilege[]>(this.privilegeUrl, privileges);
  }

  /**
   * update user's status is logout
   * @param userId
   * @returns
   */
  updateUserLogout(userId: string) {
    const params = new HttpParams().set('userId', userId + '');
    return this.http.get(`${this.userUrl}/update-user-logout`, { params });
  }

  /**
   * update user's status is login
   * @param userId
   * @param id
   * @returns
   */
  updateUserLogin(userId: string, id: Number) {
    const params = new HttpParams().set('userId', userId).set('id', id + '');
    return this.http.get(`${this.userUrl}/update-user-login`, { params });
  }

  /**
   * get all privileges
   *
   * @returns
   */
  public getAllPrivileges(): Observable<Privilege[]> {
    return this.http.get<Privilege[]>(`${this.privilegeUrl}/get-all-privileges`);
  }

  /**
   * get users for schedule merge
   */
  getUsersForScheduleMerge(): Observable<Array<IUser>> {
    return this.http.get<Array<IUser>>(`${this.userUrl}/get-users-for-schedule-merge`);
  }
}
