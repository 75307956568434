import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';
import { ContentDay } from 'app/model/entity/content-day';
import { CalendarInformation } from 'app/model/entity/calendar-information';

@Injectable({
  providedIn: 'root'
})
export class ScheduleContentDayService {
  private scheduleContentDayUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/schedule-content-days';

  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * Be used by SCHEDULE-REGISTRATRION-COMPONENT
   * get list content day by id channel
   *
   * @param scheduleContentDays
   * @param deviceId id device
   * @param isUnlimited
   */
  updateContentDaysSchedule(scheduleContentDays: Array<ContentDay>, isUnlimited: boolean): Observable<ContentDay[]> {
    const params = new HttpParams().set('isUnlimited', isUnlimited + '');
    return this.http.post<ContentDay[]>(
      `${this.scheduleContentDayUrl}/${Constant.SCHEDULE_REGISTRATION_COMPONENT_URL}/update-content-days-schedule-registration`,
      scheduleContentDays,
      { params }
    );
  }

  /**
   * Be used by SCHEDULE-COMPONENT
   * get list content day
   */
  getContentDaysSchedule(): Observable<ContentDay[]> {
    return this.http.get<ContentDay[]>(
      `${this.scheduleContentDayUrl}/${Constant.SCHEDULE_REGISTRATION_COMPONENT_URL}/get-content-days-schedule-registration`
    );
  }

  /**
   * check exist data of calendar
   * @returns
   */
  checkExistDataOfCalendar(): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.scheduleContentDayUrl}/${Constant.SCHEDULE_REGISTRATION_COMPONENT_URL}/check-exist-data-of-calendar`
    );
  }

  /**
   * export calendar schedule registration
   * @param calendarInfo
   * @returns
   */
  exportCalendarScheduleRegistration(calendarInfo: CalendarInformation): Observable<any> {
    const params = new HttpParams().set('language', this.commonService.getCommonObject().setting?.language);
    return this.http.post(
      `${this.scheduleContentDayUrl}/${Constant.SCHEDULE_REGISTRATION_COMPONENT_URL}/export-calendar-schedule-registration`,
      calendarInfo,
      {
        responseType: 'blob',
        observe: 'response',
        params: params
      }
    );
  }
}
