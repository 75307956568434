import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { MediaOfSequence } from 'app/model/entity/simple/media-of-sequence';
import { SimplePlaylist } from 'app/model/entity/simple/simple-playlist';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for simple playlist action
 */
export class SimplePlaylistService {
  /**
   * URL for common simple playlist
   */
  simplePlaylistUrl = Constant.BACKEND_URL + Constant.DSC_SERVICE_URL + 'api/simple-playlists';

  constructor(private http: HttpClient) {}

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * get all playlists
   *
   * @returns
   */
  getAllPlaylists(): Observable<Array<SimplePlaylist>> {
    return this.http.get<Array<SimplePlaylist>>(`${this.simplePlaylistUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/get-all-playlists`);
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * get sequence of playlist
   *
   * @param id
   * @returns
   */
  getSequenceOfPlaylist(id: Number): Observable<Array<MediaOfSequence>> {
    const params = new HttpParams().set('playlistId', `${id}`);
    return this.http.get<Array<MediaOfSequence>>(
      `${this.simplePlaylistUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/get-sequence-of-playlist`,
      { params }
    );
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * save simple playlist
   *
   * @param simplePlaylist
   * @returns
   */
  save(simplePlaylist: SimplePlaylist): Observable<SimplePlaylist> {
    return this.http.post<SimplePlaylist>(`${this.simplePlaylistUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/save`, simplePlaylist);
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * check exist playlist
   *
   * @param name
   * @param id
   * @returns
   */
  checkExistPlaylist(name: string, id: Number): Observable<boolean> {
    const params = new HttpParams().set('name', name).set('id', `${id}`);
    return this.http.get<boolean>(`${this.simplePlaylistUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/check-exist-playlist`, { params });
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * delete simple playlist
   *
   * @param id
   * @returns
   */
  deletePlaylist(id: Number) {
    const params = new HttpParams().set('playlistId', `${id}`);
    return this.http.delete(`${this.simplePlaylistUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/delete`, {
      params
    });
  }

  /**
   * Be used by SIMPLE-SIGNAGE-EDITOR
   * duplicate simple playlist
   *
   * @param simplePlaylist
   * @returns
   */
  duplicatePlaylist(simplePlaylist: SimplePlaylist): Observable<SimplePlaylist> {
    return this.http.post<SimplePlaylist>(`${this.simplePlaylistUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/duplicate`, simplePlaylist);
  }

  /**
   * Get playlists in log
   *
   * @param playlists
   * @returns
   */
  public getPlaylistsInLog(playlists: string[]): Observable<Array<string>> {
    return this.http.post<Array<string>>(
      `${this.simplePlaylistUrl}/${Constant.SIMPLE_SIGNAGE_EDITOR_URL}/get-playlist-in-file-log`,
      playlists
    );
  }
}
