import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service class for handling menu action
 */
export class MenuActionService {
  constructor() {}

  /**
   * subject for action add
   */
  private subjectAdd = new Subject<string>();
  /**
   * action add
   */
  actionAdd = this.subjectAdd.asObservable();

  /**
   * subject for action add folder
   */
  private subjectAddFolder = new Subject<string>();

  /**
   * action add folder
   */
  actionAddFolder = this.subjectAddFolder.asObservable();

  /**
   * subject for action Exit
   */
  private subjectExit = new Subject<string>();

  /**
   * action exit
   */
  actionExit = this.subjectExit.asObservable();

  /**
   * subject for action add new channel
   */
  private subjectAddNewChannel = new Subject<string>();
  /**
   * action add new channel
   */
  actionAddNewChannel = this.subjectAddNewChannel.asObservable();

  /**
   * subject for action add new daily schedule
   */
  private subjectAddNewDaily = new Subject<string>();
  /**
   * action add new daily schedule
   */
  actionAddNewDaily = this.subjectAddNewDaily.asObservable();

  /**
   * subject for action add sequence
   */
  private subjectAddSequence = new Subject<string>();
  /**
   * action add sequence
   */
  actionAddSequence = this.subjectAddSequence.asObservable();

  /**
   * subject for action edit sequence
   */
  private subjectEditSequence = new Subject<string>();
  /**
   * action edit sequence
   */
  actionEditSequence = this.subjectEditSequence.asObservable();

  /**
   * subject for action import media
   */
  private subjectImportMedia = new Subject<string>();
  /**
   * action import media
   */
  actionImportMedia = this.subjectImportMedia.asObservable();

  /**
   * subject for action open with
   */
  private subjectOpenWith = new Subject<string>();
  /**
   * action open with
   */
  actionOpenWith = this.subjectOpenWith.asObservable();

  /**
   * subject for action edit
   */
  private subjectEdit = new Subject<string>();
  /**
   * action edit
   */
  actionEdit = this.subjectEdit.asObservable();

  /**
   * subject for action delete
   */
  private subjectDelete = new Subject<string>();
  /**
   * action delete
   */
  actionDelete = this.subjectDelete.asObservable();

  /**
   * subject for action change template
   */
  private subjectChangeTemplate = new Subject<string>();
  /**
   * action change template
   */
  actionChangeTemplate = this.subjectChangeTemplate.asObservable();

  /**
   * subject for action set free area
   */
  private subjectSetFreeArea = new Subject<string>();

  /**
   * action set free area
   */
  actionSetFreeArea = this.subjectSetFreeArea.asObservable();

  /**
   * Subject for action clear field
   */
  private subjectClearField = new Subject<string>();

  /**
   * action set free area
   */
  actionClearField = this.subjectClearField.asObservable();

  /**
   * subject for action duplicate
   */
  private subjectDuplicate = new Subject<string>();
  /**
   * action duplicate
   */
  actionDuplicate = this.subjectDuplicate.asObservable();

  /**
   * subject for action save
   */
  private subjectSave = new Subject<string>();
  /**
   * action save
   */
  actionSave = this.subjectSave.asObservable();

  /**
   * subject for action save as
   */
  private subjectSaveAs = new Subject<string>();
  /**
   * action save as
   */
  actionSaveAs = this.subjectSaveAs.asObservable();

  /**
   * subject for action undo
   */
  private subjectUndo = new Subject<string>();
  /**
   * action undo
   */
  actionUndo = this.subjectUndo.asObservable();

  /**
   * subject for action redo
   */
  private subjectRedo = new Subject<string>();
  /**
   * action redo
   */
  actionRedo = this.subjectRedo.asObservable();

  /**
   * subject for action updateTime
   */
  private subjectUpdateTime = new Subject<string>();
  /**
   * action updateTime
   */
  actionUpdateTime = this.subjectUpdateTime.asObservable();

  /**
   * subject for action update text highlight
   */
  private subjectUpdateTextHighlight = new Subject<string>();
  /**
   * action update text highlight
   */
  actionUpdateTextHighlight = this.subjectUpdateTextHighlight.asObservable();

  /**
   * subject for action channel area preview
   */
  private subjectChannelAreaPreview = new Subject<string>();
  /**
   * action channel area preview
   */
  actionChannelAreaPreview = this.subjectChannelAreaPreview.asObservable();

  /**
   * subject for action change display
   */
  private subjectChangeDisplay = new Subject<string>();
  /**
   * action change display
   */
  actionChangeDisplay = this.subjectChangeDisplay.asObservable();

  /**
   * subject for action publish data
   */
  private subjectPublishData = new Subject<string>();
  /**
   * action publish data
   */
  actionPublishData = this.subjectPublishData.asObservable();

  /**
   * subject for action change label
   */
  private subjectChangeLabel = new Subject<string>();
  /**
   * action change label
   */
  actionChangeLabel = this.subjectChangeLabel.asObservable();

  /**
   * subject for action change label
   */
  private subjectManageLabel = new Subject<string>();
  /**
   * action manage label
   */
  actionManageLabel = this.subjectManageLabel.asObservable();

  /**
   * subject for action change display device
   */
  private subjectChangeDisplayDevice = new Subject<string>();
  /**
   * action change display
   */
  actionChangeDisplayDevice = this.subjectChangeDisplayDevice.asObservable();

  /**
   * subject for action add new custom tag
   */
  private subjectAddNewCustomTag = new Subject<string>();
  /**
   * action add new custom tag
   */
  actionAddNewCustomTag = this.subjectAddNewCustomTag.asObservable();

  /**
   * subject for action select custom tag
   */
  private subjectSelectCustomTag = new Subject<string>();
  /**
   * action select custom tag
   */
  actionSelectCustomTag = this.subjectSelectCustomTag.asObservable();

  /**
   * subject for action edit custom tag
   */
  private subjectEditCustomTag = new Subject<string>();
  /**
   * action edit custom tag
   */
  actionEditCustomTag = this.subjectEditCustomTag.asObservable();

  /**
   * subject for action sort and filter
   */
  private subjectSortAndFilter = new Subject<string>();
  /**
   * action sort and filter
   */
  actionSortAndFilter = this.subjectSortAndFilter.asObservable();

  /**
   * subject for action sort and filter Daily
   */
  private subjectSortAndFilterDaily = new Subject<string>();
  /**
   * action sort and filter Daily
   */
  actionSortAndFilterDaily = this.subjectSortAndFilterDaily.asObservable();

  /**
   * subject for action delivery
   */
  private subjectDelivery = new Subject<string>();
  /**
   * action delivery
   */
  actionDelivery = this.subjectDelivery.asObservable();

  /**
   * subject for action download publish file
   */
  private subjectDownloadPublishFile = new Subject<string>();
  /**
   * action download publish file
   */
  actionDownloadPublishFile = this.subjectDownloadPublishFile.asObservable();

  /**
   * subject for action sync setting
   */
  private subjectSimpleSyncSetting = new Subject<string>();
  /**
   * action sync setting
   */
  actionSimpleSyncSetting = this.subjectSimpleSyncSetting.asObservable();

  /**
   * subject for action emergency
   */
  private subjectEmergency = new Subject<string>();
  /**
   * action emergency
   */
  actionEmergency = this.subjectEmergency.asObservable();

  /**
   * subject for action change label
   */
  private subjectExportRoute = new Subject<string>();
  /**
   * action change label
   */
  actionExportRoute = this.subjectExportRoute.asObservable();
  /**
   * subject for action setting
   */
  private subjectSetting = new Subject<string>();
  /**
   * action setting
   */
  actionSetting = this.subjectSetting.asObservable();
  /**
   * subject for action open delivery job
   */
  private subjectOpenDeliveryJob = new Subject<string>();
  /**
   * action open delivery job
   */
  actionOpenDeliveryJob = this.subjectOpenDeliveryJob.asObservable();
  /**
   * subject for action open setting
   */
  private subjectOpenSetting = new Subject<string>();
  /**
   * action open setting
   */
  actionOpenSetting = this.subjectOpenSetting.asObservable();
  /**
   * subject for action create output file
   */
  private subjectCreateOutputFile = new Subject<string>();
  /**
   * action create output file
   */
  actionCreateOutputFile = this.subjectCreateOutputFile.asObservable();
  /**
   * subject for action save content detail
   */
  private subjectSaveContentDetail = new Subject<string>();
  /**
   * action save content detail
   */
  actionSaveContentDetail = this.subjectSaveContentDetail.asObservable();

  /**
   * subject for action clear setting (dashboard)
   */
  private subjectClearSetting = new Subject<string>();
  /**
   * action clear setting (dashboard)
   */
  actionClearSetting = this.subjectClearSetting.asObservable();

  /**
   * subject for action get latest media log
   */
  private subjectGetLatestMediaLog = new Subject<string>();
  /**
   * action get latest media log
   */
  actionGetLatestMediaLog = this.subjectGetLatestMediaLog.asObservable();

  /**
   * subject for action export media log
   */
  private subjectExportMediaLog = new Subject<string>();
  /**
   * action export media log
   */
  actionExportMediaLog = this.subjectExportMediaLog.asObservable();

  /**
   * subject for action export content report
   */
  private subjectExportContentReport = new Subject<string>();
  /**
   * action export content report
   */
  actionExportContentReport = this.subjectExportContentReport.asObservable();

  /**
   * subject for action add
   */
  private subjectDynamicMessage = new Subject<string>();
  /**
   * action add
   */
  actionDynamicMessage = this.subjectDynamicMessage.asObservable();

  /**
   * subject for action import time table
   */
  private subjectImportTimetable = new Subject<string>();
  /**
   * action import time table
   */
  actionImportTimetable = this.subjectImportTimetable.asObservable();

  /**
   * subject for action export time table
   */
  private subjectExportTimetable = new Subject<string>();
  /**
   * action export time table
   */
  actionExportTimetable = this.subjectExportTimetable.asObservable();

  /**
   * subject for action export calendar
   */
  private subjectExportCalendar = new Subject<string>();
  /**
   * action export calendar
   */
  actionExportCalendar = this.subjectExportCalendar.asObservable();

  /**
   * subject for action edit item name
   */
  private subjectEditItemName = new Subject<string>();
  /**
   * action edit item name
   */
  actionEditItemName = this.subjectEditItemName.asObservable();

  /**
   * subject for action edit folder
   */
  private subjectEditFolder = new Subject<string>();

  /**
   * action edit folder
   */
  actionEditFolder = this.subjectEditFolder.asObservable();

  /**
   * subject for action delete item
   */
  private subjectDeleteItem = new Subject<string>();

  /**
   * action delete folder
   */
  actionDeleteItem = this.subjectDeleteItem.asObservable();

  /**
   * subject for action reference setting
   */
  private subjectReferenceSetting = new Subject<string>();

  /**
   * action reference setting
   */
  actionReferenceSetting = this.subjectReferenceSetting.asObservable();

  /**
   * subject for action operation setting
   */
  private subjectOperationSetting = new Subject<string>();

  /**
   * action operation setting
   */
  actionOperationSetting = this.subjectOperationSetting.asObservable();

  /**
   * subject for action change date line
   */
  private subjectChangeDateLine = new Subject<string>();

  /**
   * action change date line
   */
  actionChangeDateLine = this.subjectChangeDateLine.asObservable();

  /**
   * subject for action change timing area
   */
  private subjectSwitchTimingArea = new Subject<string>();

  /**
   * action switch timing area
   */
  actionSwitchTimingArea = this.subjectSwitchTimingArea.asObservable();

  /**
   * subject for action change expiration
   */
  private subjectChangeExpiration = new Subject<string>();

  /**
   * action change expiration
   */
  actionChangeExpiration = this.subjectChangeExpiration.asObservable();

  /**
   * subject for action change expiration
   */
  private subjectChangePlaybackTime = new Subject<string>();

  /**
   * action change playback time
   */
  actionChangePlaybackTime = this.subjectChangePlaybackTime.asObservable();

  /**
   * subject for action reflect data from Route List Editor
   */
  private subjectReflectDataFromRouteListEditor = new Subject<string>();

  /**
   * action reflect data from Route List Editor
   */
  actionReflectDataFromRouteListEditor = this.subjectReflectDataFromRouteListEditor.asObservable();

  /**
   * subject for action import route list
   */
  private subjectImportRouteList = new Subject<string>();

  /**
   * action action import route list
   */
  actionImportRouteList = this.subjectImportRouteList.asObservable();

  /**
   * subject for action export route list
   */
  private subjectExportRouteList = new Subject<string>();

  /**
   * action action export route list
   */
  actionExportRouteList = this.subjectExportRouteList.asObservable();

  /**
   * subject for action Edit data in Destination
   */
  private subjectEditDataDestination = new Subject<string>();

  /**
   * action action Edit data in Destination
   */
  actionEditDataDestination = this.subjectEditDataDestination.asObservable();

  /**
   * subject for action delete route list in Destination
   */
  private subjectDeleteRouteDestination = new Subject<string>();

  /**
   * action action delete route list in Destination
   */
  actionDeleteRouteDestination = this.subjectDeleteRouteDestination.asObservable();

  /**
   * subject for action change template route list in Destination
   */
  private subjectChangeTemplateRouteDestination = new Subject<string>();

  /**
   * action action change template route list in Destination
   */
  actionChangeTemplateRouteDestination = this.subjectChangeTemplateRouteDestination.asObservable();

  /**
   * subject for action import template in LCD
   */
  private subjectImportTemplate = new Subject<string>();

  /**
   * action action import template in LCD
   */
  actionImportTemplate = this.subjectImportTemplate.asObservable();

  /**
   * subject for action Export template in LCD
   */
  private subjectExportTemplate = new Subject<string>();

  /**
   * action action Export template in LCD
   */
  actionExportTemplate = this.subjectExportTemplate.asObservable();

  /**
   * subject for action setting upper limit in LCD
   */
  private subjectSettingUpperLimit = new Subject<string>();

  /**
   * action action setting upper limit in LCD
   */
  actionSettingUpperLimit = this.subjectSettingUpperLimit.asObservable();

  /**
   * subject for action import schedule merge
   */
  private subjectImportScheduleMerge = new Subject<string>();
  /**
   * action import schedule Merge
   */
  actionImportScheduleMerge = this.subjectImportScheduleMerge.asObservable();

  /**
   * subject for action import schedule merge
   */
  private subjectMergeSchedule = new Subject<string>();
  /**
   * action import schedule Merge
   */
  actionMergeSchedule = this.subjectMergeSchedule.asObservable();

  /**
   * subject for create daily schedule
   */
  private subjectCreateDailySchedule = new Subject<string>();

  /**
   * action create daily schedule
   */
  actionCreateDailySchedule = this.subjectCreateDailySchedule.asObservable();

  /**
   * subject for action import schedule merge
   */
  private subjectUpdateData = new Subject<string>();
  /**
   * action import schedule Merge
   */
  actionUpdateData = this.subjectUpdateData.asObservable();

  /**
   * subject for action import schedule registration
   */
  private subjectImportScheduleRegistration = new Subject<string>();

  /**
   * action import schedule registration
   */
  actionImportScheduleImportRegistration = this.subjectImportScheduleRegistration.asObservable();

  /**
   * subject for action update data schedule registration
   */
  private subjectUpdateDataScheduleRegistration = new Subject<string>();

  /**
   * action update data schedule registration
   */
  actionUpdateDataScheduleRegistration = this.subjectUpdateDataScheduleRegistration.asObservable();

  /**
   * subject for action export schedule registration
   */
  private subjectExportScheduleRegistration = new Subject<string>();

  /**
   * action import schedule registration
   */
  actionExportScheduleImportRegistration = this.subjectExportScheduleRegistration.asObservable();

  /**
   * subject for export calendar schedule registration
   */
  private subjectExportCalendarScheduleRegistration = new Subject<string>();

  /**
   * action export calendar schedule registration
   */
  actionExportCalendarScheduleRegistration = this.subjectExportCalendarScheduleRegistration.asObservable();

  /**
   * subject for insert row
   */
  private subjectInsertRow = new Subject<string>();

  /**
   * action insert row
   */
  actionInsertRow = this.subjectInsertRow.asObservable();

  /**
   * subject for edit row
   */
  private subjectEditRow = new Subject<string>();

  /**
   * action edit row
   */
  actionEditRow = this.subjectEditRow.asObservable();

  /**
   * subject for delete row
   */
  private subjectDeleteRow = new Subject<string>();

  /**
   * action delete row
   */
  actionDeleteRow = this.subjectDeleteRow.asObservable();

  /**
   * subject duplicate row
   */
  private subjectDuplicateRow = new Subject<string>();

  /**
   * action duplicate row
   */
  actionDuplicateRow = this.subjectDuplicateRow.asObservable();

  /**
   * subject show/hidden item
   */
  private subjectShowHiddenItemSetting = new Subject<string>();

  /**
   * action show/hidden item
   */
  actionShowHiddenItemSetting = this.subjectShowHiddenItemSetting.asObservable();

  /**
   * add item
   * @param {string} moduleName module name
   */
  add(moduleName: string) {
    this.subjectAdd.next(moduleName);
  }

  /**
   * add folder
   * @param {string} moduleName module name
   */
  addFolder(moduleName: string) {
    this.subjectAddFolder.next(moduleName);
  }

  /**
   * Exit
   * @param {string} moduleName module name
   */
  exit(moduleName: string) {
    this.subjectExit.next(moduleName);
  }

  /**
   * add channel
   * @param {string} moduleName module name
   */
  addNewChannel(moduleName: string) {
    this.subjectAddNewChannel.next(moduleName);
  }

  /**
   * add daily schedule
   * @param {string} moduleName module name
   */
  addNewDaily(moduleName: string) {
    this.subjectAddNewDaily.next(moduleName);
  }

  /**
   * add sequence in menu File
   * @param {string} moduleName module name
   */
  addSequence(moduleName: string) {
    this.subjectAddSequence.next(moduleName);
  }

  /**
   * edit sequence in menu File
   * @param {string} moduleName module name
   */
  editSequence(moduleName: string) {
    this.subjectEditSequence.next(moduleName);
  }

  /**
   * import media
   * @param {string} moduleName module name
   */
  importMedia(moduleName: string) {
    this.subjectImportMedia.next(moduleName);
  }

  /**
   * open with software
   * @param {string} moduleName module name
   */
  openWith(moduleName: string) {
    this.subjectOpenWith.next(moduleName);
  }

  /**
   * edit item
   * @param {string} moduleName module name
   */
  edit(moduleName: string) {
    this.subjectEdit.next(moduleName);
  }

  /**
   * delete item
   * @param {string} moduleName module name
   */
  delete(moduleName: string) {
    this.subjectDelete.next(moduleName);
  }

  /**
   * change template item
   * @param {string} moduleName module name
   */
  changeTemplate(moduleName: string) {
    this.subjectChangeTemplate.next(moduleName);
  }

  /**
   * Set free area
   * @param {string} moduleName module name
   */
  setFreeArea(moduleName: string) {
    this.subjectSetFreeArea.next(moduleName);
  }

  /**
   * Clear field free area
   * @param {string} moduleName module name
   */
  clearField(moduleName: string) {
    this.subjectClearField.next(moduleName);
  }

  /**
   * change template item
   * @param {string} moduleName module name
   */
  changeDisplay(moduleName: string) {
    this.subjectChangeDisplay.next(moduleName);
  }

  /**
   * duplicate item
   * @param {string} moduleName module name
   */
  duplicate(moduleName: string) {
    this.subjectDuplicate.next(moduleName);
  }

  /**
   * save item
   * @param {string} moduleName module name
   */
  save(moduleName: string) {
    this.subjectSave.next(moduleName);
  }

  /**
   * save as item
   * @param {string} moduleName module name
   */
  saveAs(moduleName: string) {
    this.subjectSaveAs.next(moduleName);
  }

  /**
   * publish item
   * @param {string} moduleName module name
   */
  publish(moduleName: string) {
    this.subjectPublishData.next(moduleName);
  }
  /**
   * change label item
   * @param {string} moduleName module name
   */
  changeLabel(moduleName: string) {
    this.subjectChangeLabel.next(moduleName);
  }
  /**
   * manage label
   * @param {string} moduleName module name
   */
  manageLabel(moduleName: string) {
    this.subjectManageLabel.next(moduleName);
  }

  /**
   * change display device
   * @param {string} moduleName module name
   */
  changeDisplayDevice(moduleName: string) {
    this.subjectChangeDisplayDevice.next(moduleName);
  }

  /**
   * add new custom tag
   * @param {string} moduleName module name
   */
  addNewCustomTag(moduleName: string) {
    this.subjectAddNewCustomTag.next(moduleName);
  }

  /**
   * select custom tag
   * @param {string} moduleName module name
   */
  selectCustomTag(moduleName: string) {
    this.subjectSelectCustomTag.next(moduleName);
  }
  /**
   * edit custom tag
   * @param {string} moduleName module name
   */
  editCustomTag(moduleName: string) {
    this.subjectEditCustomTag.next(moduleName);
  }
  /**
   * sort and filter item
   * @param {string} moduleName module name
   */
  toggleSortAndFilter(moduleName: string) {
    this.subjectSortAndFilter.next(moduleName);
  }

  toggleSortAndFilterDaily(moduleName: string) {
    this.subjectSortAndFilterDaily.next(moduleName);
  }
  /**
   * delivery item
   * @param {string} moduleName module name
   */
  delivery(moduleName: string) {
    this.subjectDelivery.next(moduleName);
  }

  /**
   * download publish file item
   * @param {string} moduleName module name
   */
  downloadPublishFile(moduleName: string) {
    this.subjectDownloadPublishFile.next(moduleName);
  }

  /**
   * sync setting item
   * @param {string} moduleName module name
   */
  simpleSyncSetting(moduleName: string) {
    this.subjectSimpleSyncSetting.next(moduleName);
  }

  /**
   * emergency item
   * @param {string} moduleName module name
   */
  emergency(moduleName: string) {
    this.subjectEmergency.next(moduleName);
  }
  /**
   * open delivery job
   *  @param {string} moduleName module name
   */
  openDeliveryJob(moduleName: string) {
    this.subjectOpenDeliveryJob.next(moduleName);
  }

  /**
   * open delivery manager setting
   *  @param {string} moduleName module name
   */
  openSetting(moduleName: string) {
    this.subjectOpenSetting.next(moduleName);
  }

  /**
   * undo
   * @param moduleName
   */
  undo(moduleName: string) {
    this.subjectUndo.next(moduleName);
  }
  /**
   * redo
   * @param moduleName
   */
  redo(moduleName: string) {
    this.subjectRedo.next(moduleName);
  }
  /**
   * setting
   * @param moduleName
   */
  setting(moduleName: string) {
    this.subjectSetting.next(moduleName);
  }

  /**
   * update Timing
   * @param moduleName
   */
  updateTime(moduleName: string) {
    this.subjectUpdateTime.next(moduleName);
  }
  /**
   * channel Area Preview
   * @param moduleName
   */
  channelAreaPreview(moduleName: string) {
    this.subjectChannelAreaPreview.next(moduleName);
  }

  /**
   * save content detail
   * @param {string} moduleName module name
   */
  saveContentDetail(moduleName: string) {
    this.subjectSaveContentDetail.next(moduleName);
  }
  /**
   * create output file
   * @param {string} moduleName module name
   */
  createOutputFile(moduleName: string) {
    this.subjectCreateOutputFile.next(moduleName);
  }

  /**
   * clear setting (dashboard)
   * @param {string} moduleName module name
   */
  clearSetting(moduleName: string) {
    this.subjectClearSetting.next(moduleName);
  }

  /**
   * get latest media log
   * @param {string} moduleName module name
   */
  getLatestMediaLog(moduleName: string) {
    this.subjectGetLatestMediaLog.next(moduleName);
  }

  /**
   * export media log
   * @param {string} moduleName module name
   */
  exportMediaLog(moduleName: string) {
    this.subjectExportMediaLog.next(moduleName);
  }

  /**
   * export content report
   * @param {string} moduleName module name
   */
  exportContentReport(moduleName: string) {
    this.subjectExportContentReport.next(moduleName);
  }

  /**
   * import timetable
   * @param {string} moduleName module name
   */
  importTimetable(moduleName: string) {
    this.subjectImportTimetable.next(moduleName);
  }

  /**
   * export timetable
   * @param {string} moduleName module name
   */
  exportTimetable(moduleName: string) {
    this.subjectExportTimetable.next(moduleName);
  }

  /**
   * export calendar
   * @param {string} moduleName module name
   */
  exportCalendar(moduleName: string) {
    this.subjectExportCalendar.next(moduleName);
  }

  /**
   * edit item name
   * @param {string} moduleName module name
   */
  editItemName(moduleName: string) {
    this.subjectEditItemName.next(moduleName);
  }

  /**
   *
   * @param moduleName
   */
  openDynamicMessage(moduleName: string) {
    this.subjectDynamicMessage.next(moduleName);
  }

  /**
   * edit folder
   * @param {string} moduleName module name
   */
  editFolder(moduleName: string) {
    this.subjectEditFolder.next(moduleName);
  }

  /**
   * delete folder
   * @param {string} moduleName module name
   */
  deleteItem(moduleName: string) {
    this.subjectDeleteItem.next(moduleName);
  }

  /**
   * reference setting
   * @param {string} moduleName module name
   */
  referenceSetting(moduleName: string) {
    this.subjectReferenceSetting.next(moduleName);
  }

  /**
   * operation setting
   * @param {string} moduleName module name
   */
  operationSetting(moduleName: string) {
    this.subjectOperationSetting.next(moduleName);
  }

  /**
   * show/hidden item setting
   * @param moduleName
   */
  showHiddenItemSetting(moduleName: string) {
    this.subjectShowHiddenItemSetting.next(moduleName);
  }

  /**
   * change date line
   * @param moduleName
   */
  changeDateLine(moduleName: string) {
    this.subjectChangeDateLine.next(moduleName);
  }

  /**
   * switch timing area
   *   @param moduleName
   */
  switchTimingArea(moduleName: string) {
    this.subjectSwitchTimingArea.next(moduleName);
  }

  /**
   * change expiration
   * @param moduleName
   */
  changeExpiration(moduleName: string) {
    this.subjectChangeExpiration.next(moduleName);
  }

  /**
   * change playback time
   * @param moduleName
   */
  changePlaybackTime(moduleName: string) {
    this.subjectChangePlaybackTime.next(moduleName);
  }

  /**
   * Reflect data from route list editor
   *
   * @param moduleName
   */
  reflectDataFromRouteListEditor(moduleName: string) {
    this.subjectReflectDataFromRouteListEditor.next(moduleName);
  }

  /**
   * Import route list
   *
   * @param moduleName
   */
  importRouteList(moduleName: string) {
    this.subjectImportRouteList.next(moduleName);
  }

  /**
   * Export route list
   *
   * @param moduleName
   */
  exportRouteList(moduleName: string) {
    this.subjectExportRouteList.next(moduleName);
  }

  /**
   * Edit data Destination
   *
   * @param moduleName
   */
  editDataDestination(moduleName: string) {
    this.subjectEditDataDestination.next(moduleName);
  }

  /**
   * Delete route list destination
   *
   * @param moduleName
   */
  deleteRouteListDestination(moduleName: string) {
    this.subjectDeleteRouteDestination.next(moduleName);
  }

  /**
   * Delete route list destination
   *
   * @param moduleName
   */
  changeTemplateRouteListDestination(moduleName: string) {
    this.subjectChangeTemplateRouteDestination.next(moduleName);
  }

  /**
   * import template
   * @param {string} moduleName module name
   */
  importTemplate(moduleName: string) {
    this.subjectImportTemplate.next(moduleName);
  }

  /**
   * export template
   * @param {string} moduleName module name
   */
  exportTemplate(moduleName: string) {
    this.subjectExportTemplate.next(moduleName);
  }

  /**
   * setting upper limit
   * @param {string} moduleName module name
   */
  settingUpperLimit(moduleName: string) {
    this.subjectSettingUpperLimit.next(moduleName);
  }

  /**
   * import Schedule Merge
   * @param {string} moduleName module name
   */
  importScheduleMerge(moduleName: string) {
    this.subjectImportScheduleMerge.next(moduleName);
  }

  /**
   * schedule Merge
   * @param {string} moduleName module name
   */
  mergeSchedule(moduleName: string) {
    this.subjectMergeSchedule.next(moduleName);
  }

  createDailySchedule(moduleName: string) {
    this.subjectCreateDailySchedule.next(moduleName);
  }

  /**
   * Update data
   * @param {string} moduleName module name
   */
  updateData(moduleName: string) {
    this.subjectUpdateData.next(moduleName);
  }

  /**
   * import schedule registration
   * @param moduleName
   */
  importScheduleRegistration(moduleName: string) {
    this.subjectImportScheduleRegistration.next(moduleName);
  }

  /**
   * import schedule registration
   * @param moduleName
   */
  updateDataRegistration(moduleName: string) {
    this.subjectUpdateDataScheduleRegistration.next(moduleName);
  }
  /**
   * export schedule registration
   * @param moduleName
   */
  exportScheduleRegistration(moduleName: string) {
    this.subjectExportScheduleRegistration.next(moduleName);
  }

  /**
   * export calendar
   * @param moduleName
   */
  exportCalendarScheduleRegistration(moduleName: string) {
    this.subjectExportCalendarScheduleRegistration.next(moduleName);
  }

  /**
   * insert row
   * @param {string} moduleName module name
   */
  insertRow(moduleName: string) {
    this.subjectInsertRow.next(moduleName);
  }

  /**
   * edit row
   * @param {string} moduleName module name
   */
  editRow(moduleName: string) {
    this.subjectEditRow.next(moduleName);
  }

  /**
   * delete row
   * @param {string} moduleName module name
   */
  deleteRow(moduleName: string) {
    this.subjectDeleteRow.next(moduleName);
  }

  /**
   * duplicate row
   * @param {string} moduleName module name
   */
  duplicateRow(moduleName: string) {
    this.subjectDuplicateRow.next(moduleName);
  }

  /**
   * update text high light setting
   * @param {string} moduleName module name
   */
  updateTextHighlightSetting(moduleName: string) {
    this.subjectUpdateTextHighlight.next(moduleName);
  }
}
